import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FaClock, FaFileSignature, FaHandSpock, FaIdCard, FaSkullCrossbones} from "react-icons/fa";
import logoWhite from "./logo/logo_white.png";
import {useState} from "react";
import Telegram from 'telegram-send-message'
import firebase from "firebase/compat";
import {nanoid} from "nanoid";
import moment from "moment";
import * as emailjs from "emailjs-com";
import {Navbar} from "./OnePageHome/Navbar";
import SignatureCanvas from 'react-signature-canvas';
import {MetaDecorator} from "./utils/MetaDecorator";


export function Register() {

    firebase.analytics().logEvent('register_view', {value:'true'})

    const [showRiepilogo, setShowRiepilogo] = useState(false)

    // DATI PERSONALI
    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    // CF
    const [cf, setCf] = useState('')
    // NASCITA
    const [comunenascita, setComunenascita] = useState('')
    const [provincianascita, setProvincianascita] = useState('')
    const [datanascita, setDatanascita] = useState('')
    // RESIDENZA
    const [comuneresidenza, setComuneresidenza] = useState('')
    const [provinciaresidenza, setProvinciaresidenza] = useState('')
    const [indirizzoresidenza, setIndirizzoresidenza] = useState('')
    const [capresidenza, setCapresidenza] = useState('')
    // CONTATTI
    const [email, setEmail] = useState('')
    const [telefono, setTelefono] = useState('')
    // CONSENSI
    const [richiesta, setRichiesta] = useState(false)
    const [statuto, setStatuto] = useState(false)
    const [privacy, setPrivacy] = useState(false)


    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [showComplete, setShowComplete] = useState(false)

    const [signature, setSignature] = useState({})
    const [sigURL, setSigURL] = useState('')

    return (
        <div className="py-5">
            <MetaDecorator
                title="Registrati | The Place APS"
                description="Diventa anche tu socio di The Place APS. Per te fantastici benefici e opportunità."
                keywords="associarsi, tesseramento, registrazione, sign up, iscriviti, diventa socio"
            />
            <Navbar />
            <Container className="mt-5 pt-5">
                <div className="mb-5 mt-2 text-center">
                    <img
                        src={logoWhite}
                        height={100}
                        alt="logoThePlace"
                    />
                </div>
                <div className="text-center mb-5">
                    <h2 className="fw-bold">Diventa parte della <span className="text-decoration-underline">ThePlace Family</span> !</h2>
                    <p>Lo sanno tutti: più si è, più ci si diverte! Diventa socio ordinario anche tu per partecipare alle attività organizzate e promosse dalla nostra associazione.</p>
                </div>
            </Container>
            <Container fluid className="bg-light">
                <div className="py-5">
                    <Row className="text-center text-dark">
                        <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4 mb-lg-0">
                            <FaFileSignature
                                color="#dc3545"
                                size="2.5em"
                                className="mb-2"
                            />
                            <div>
                                <h4 className="fw-bold">Compila il form</h4>
                                <p className="fw-light small">Riceverai una mail di conferma.</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4 mb-lg-0">
                            <FaClock
                                color="#dc3545"
                                size="2.5em"
                                className="mb-2"
                            />
                            <div>
                                <h4 className="fw-bold">Attendi la risposta</h4>
                                <p className="fw-light small">Di solito ci mettiamo pochissimo.</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4 mb-lg-0">
                            <FaIdCard
                                color="#dc3545"
                                size="2.5em"
                                className="mb-2"
                            />
                            <div>
                                <h4 className="fw-bold">Paga la quota</h4>
                                <p className="fw-light small">Ricevi la tessera associativa.</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                            <FaHandSpock
                                color="#dc3545"
                                size="2.5em"
                                className="mb-2"
                            />
                            <div>
                                <h4 className="fw-bold">Partecipa agli eventi</h4>
                                <p className="fw-light small">Divertiti!</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container className="py-5">
                <div className="text-center mb-4">
                    <p>Ti chiediamo alcune informazioni per poter emettere la tua tessera associativa.</p>
                    <p className="small text-decoration-underline">N.B. Tutti i campi sono obbligatori.</p>
                </div>
                <div>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label className="small m-0">Nome *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10 mb-2"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small m-0">Cognome *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10 mb-2"
                                    value={cognome}
                                    onChange={e => setCognome(e.target.value)}
                                />
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label className="small m-0">Codice Fiscale *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10 mb-2"
                                    value={cf}
                                    onChange={e => setCf(e.target.value)}
                                />
                            </Form.Group>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="small m-0">Comune di Nascita *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={comunenascita}
                                            onChange={e => setComunenascita(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="small m-0">Provincia *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={provincianascita}
                                            onChange={e => setProvincianascita(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label className="small m-0">Data di Nascita *</Form.Label>
                                <Form.Control
                                    type="date"
                                    className="rounded10 mb-2"
                                    value={datanascita}
                                    onChange={e => setDatanascita(e.target.value)}
                                />
                            </Form.Group>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="small m-0">Comune di Residenza *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={comuneresidenza}
                                            onChange={e => setComuneresidenza(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="small m-0">Provincia *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={provinciaresidenza}
                                            onChange={e => setProvinciaresidenza(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="small m-0">Indirizzo di Residenza *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={indirizzoresidenza}
                                            onChange={e => setIndirizzoresidenza(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="small m-0">CAP *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="rounded10 mb-2"
                                            value={capresidenza}
                                            onChange={e => setCapresidenza(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Form.Group>
                                <Form.Label className="small m-0">Email *</Form.Label>
                                <Form.Control
                                    type="email"
                                    className="rounded10 mb-2"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small m-0">Telefono *</Form.Label>
                                <Form.Control
                                    type="tel"
                                    className="rounded10 mb-2"
                                    value={telefono}
                                    onChange={e => setTelefono(e.target.value)}
                                />
                            </Form.Group>
                            <br />
                            <Form.Check
                                type="switch"
                                className="mb-2"
                                label={<p className="small">Chiedo al Consiglio Direttivo di essere iscritto in qualità di Socio Ordinario a questa Associazione per l’anno 2022.</p>}
                                checked={richiesta}
                                onClick={e => richiesta===false ? setRichiesta(true) : setRichiesta(false)}
                            />
                            <Form.Check
                                type="switch"
                                className="mb-2"
                                label={<p className="small">Accetto senza riserve lo <a href="https://firebasestorage.googleapis.com/v0/b/theplace-identity.appspot.com/o/documenti%2FThePlace_Statuto.pdf?alt=media&token=5261b9c9-b3fb-4b39-9c41-1ff4a9c9e642" target="_blank" rel="noreferrer">Statuto</a>, l'<a href="https://firebasestorage.googleapis.com/v0/b/theplace-identity.appspot.com/o/documenti%2FThePlace_AttoCostitutivo.pdf?alt=media&token=7d2a89b9-5ae1-4967-b3c1-27cfb6b3e09e" target="_blank" rel="noreferrer">Atto Costitutivo</a>, il Regolamento interno generale e di settore, che dichiaro di conoscere e condividere. Approvo, ai sensi dell’art.1341 del C.C., l’articolo dello Statuto Sociale che mi  impegna, in caso di controversie, a non adire altre Autorità che  non  siano  quelle dell'Associazione stessa. Mi impegno a versare la quota associativa per l’anno indicato che ammonta a €5,00 (cinque/00).</p>}
                                checked={statuto}
                                onClick={e => statuto===false ? setStatuto(true) : setStatuto(false)}
                            />
                            <Form.Check
                                type="switch"
                                className="mb-2"
                                label={<p className="small">Ricevuta l’informativa di cui alla legge 675/96 e del Dlgs 196 del 30/06/2003 sulla PRIVACY, esprimo il consenso al trattamento dei dati che mi riguardano, da parte di codesta Associazione per le sue finalità istituzionali, connesse o strumentali, nonché alla loro comunicazione a terzi rientranti in quelle categorie di soggetti espressamente previste dalle normative fiscali per adempiere obblighi di Legge (Commercialista, Pubblica Amministrazione, Enti Pubblici). Esprime il consenso al trattamento dei dati a me riferibili tra quelli sensibili in base agli art. 22,23 e 24 della citata Legge 675/96 sostituiti dagli art.60 e 62 del Dlgs 196/2003, quando ciò sia funzionale all’esecuzione delle operazioni o dei servizi da me richiesti.</p>}
                                checked={privacy}
                                onClick={e => privacy===false ? setPrivacy(true) : setPrivacy(false)}
                            />
                            <br />

                            <div className="mb-4 d-flex justify-content-center align-items-center">
                                <div>
                                    <p className="small mb-1">Firma *</p>
                                    <div className="bg-light rounded10 mb-1" style={{width:520, height:220, padding:10}}>
                                        <SignatureCanvas penColor="black"
                                                         ref={(ref) => { setSignature(ref) }}
                                                         canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                        />
                                    </div>
                                    <div className="text-end">
                                        <Button
                                            variant="light"
                                            size="sm"
                                            className="rounded10"
                                            onClick={() => {
                                                signature.clear()
                                                setSigURL('')
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <Button
                                variant="danger"
                                className="rounded10 w-100"
                                disabled={checkInputs()}
                                onClick={() => {
                                    setShowRiepilogo(true)
                                    setSigURL(signature.getTrimmedCanvas().toDataURL('image/png'))
                                }}
                            >
                                Invia richiesta
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>


            <Modal show={showRiepilogo} onHide={() => {
                setShowRiepilogo(false)
                setSigURL('')
            }}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   className="text-dark"
                   backdrop="static"
            >
                <Modal.Header className="d-flex justify-content-center">
                    <Modal.Title className="fw-bold text-uppercase">Riepilogo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center text-decoration-underline mb-3">
                        <p>Per favore, controlla tutti i campi prima di confermare l'invio della richiesta.</p>
                    </div>
                    <Container className="px-5">
                        {rowRiepilogo('Nome', nome)}
                        {rowRiepilogo('Cognome', cognome)}
                        {rowRiepilogo('Codice Fiscale', cf)}
                        {rowRiepilogo('Comune di Nascita', comunenascita)}
                        {rowRiepilogo('Provincia di Nascita', provincianascita)}
                        {rowRiepilogo('Data di Nascita', datanascita)}
                        {rowRiepilogo('Comune di Residenza', comuneresidenza)}
                        {rowRiepilogo('Provincia di Residenza', provinciaresidenza)}
                        {rowRiepilogo('Indirizzo di Residenza', indirizzoresidenza)}
                        {rowRiepilogo('CAP di Residenza', capresidenza)}
                        {rowRiepilogo('Email', email)}
                        {rowRiepilogo('Telefono', telefono)}
                        {rowRiepilogo('Firma', <img
                            src={sigURL}
                            height={100}
                            alt="signature"
                        />)}
                        <p className="small text-center mt-2">Assicurati che la firma sia chiara e leggibile.</p>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => {
                        setShowRiepilogo(false)
                        setSigURL('')
                    }}
                        className="rounded10"
                    >
                        Modifica
                    </Button>
                    <Button variant="danger" onClick={() => uploadData()}
                            className="rounded10"
                    >
                        Conferma
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={loading}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   className="text-dark"
                   backdrop="static"
            >
                <Modal.Body className="text-center">
                    <div className="my-3">
                        {
                            error ?
                                <div>
                                    <FaSkullCrossbones
                                        size="3em"
                                        className="text-danger mb-3"
                                    />
                                    <p className="fw-bold text-danger">{error}</p>
                                </div>
                                :
                                <div>
                                    <Spinner animation="border" className="mb-3" />
                                    <h4 className="fw-bold">CARICAMENTO</h4>
                                    <p>Non chiudere il browser.</p>
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showComplete}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   className="text-light"
                   backdrop="static"

            >
                <Modal.Body className="text-center bg-success rounded">
                    <div className="my-3">
                        <h5 className="fw-bold">Richiesta inviata con successo!</h5>
                        <p>Riceverai una mail di conferma.</p>
                        <br />
                        <p className="small fw-light">Verrai rendirizzato alla Home.</p>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )

    function rowRiepilogo(label, value) {

        return (
            <Row>
                <Col xs={4} className="text-end">
                    {label}
                </Col>
                <Col xs={8} className="fw-bold">
                    {value}
                </Col>
            </Row>
        )
    }

    function validateEmail(emailAdress)
    {
        let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return !!emailAdress.match(regexEmail);
    }
    function validatePhone(phone)
    {
        let regexPhone = /^\d{10}$/;
        return !!phone.match(regexPhone);
    }

    function checkInputs() {
        let valid = true

        // eslint-disable-next-line no-unused-expressions
        nome.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        cognome.trim()==='' ? valid=false : null

        // eslint-disable-next-line no-unused-expressions
        cf.trim()==='' ? valid=false : null

        // eslint-disable-next-line no-unused-expressions
        comunenascita.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        provincianascita.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        datanascita.trim()==='' ? valid=false : null

        // eslint-disable-next-line no-unused-expressions
        comuneresidenza.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        provinciaresidenza.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        indirizzoresidenza.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        capresidenza.trim()==='' ? valid=false : null

        // eslint-disable-next-line no-unused-expressions
        email.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        !validateEmail(email) ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        telefono.trim()==='' ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        !validatePhone(telefono) ? valid=false : null

        // eslint-disable-next-line no-unused-expressions
        richiesta===false ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        statuto===false ? valid=false : null
        // eslint-disable-next-line no-unused-expressions
        privacy===false ? valid=false : null



        return !valid
    }

    function uploadData() {
        setLoading(true)
        const id = nanoid(15)
        const datarichiesta = moment().format('YYYY-MM-DD H:mm')
        firebase.firestore().collection('pendingUsers').doc(id).set({
            id: id,
            nome: nome,
            cognome: cognome,
            cf: cf,
            comunenascita: comunenascita,
            provincianascita: provincianascita,
            datanascita: datanascita,
            comuneresidenza: comuneresidenza,
            provinciaresidenza: provinciaresidenza,
            indirizzoresidenza: indirizzoresidenza,
            capresidenza: capresidenza,
            email: email,
            telefono: telefono,
            richiesta: richiesta,
            statuto: statuto,
            privacy: privacy,
            datarichiesta: datarichiesta,
            status: 'sent',
        }).then(() => {

            signature.getCanvas().toBlob((blob) => {
                firebase.storage().ref('signatures/' + cognome + nome + '.png').put(blob).then(() => {
                    SendEmail()
                    sendTelegramMessage({id, nome, cognome, cf, email, telefono, datarichiesta})
                    setLoading(false)
                    setShowRiepilogo(false)
                    setShowComplete(true)
                    firebase.analytics().logEvent('register_done', {value:'true'})
                    setTimeout(() => window.location.replace('/'), 5000)
                })
            })
        }).catch(() => {
            setError('Qualcosa è andato storto. Per favore, riprova più tardi.')
        })
    }

    function sendTelegramMessage(props) {
    const str = `NUOVA RICHIESTA ASSOCIATO | ${props.datarichiesta} | ID: ${props.id} | Nome: ${props.nome} | Cognome: ${props.cognome} | CF: ${props.cf} | Email: ${props.email} | Tel: ${props.telefono}`
        Telegram.setToken('5245347041:AAFA60E6hBwdSWUJNiRLT5Nq_XCUcYswUFo')
        Telegram.setRecipient('325275429')
        Telegram.setMessage(str)
        Telegram.send()
    }

    function SendEmail() {
        const e = {
            email: email,
            subject: 'Ricevuta richiesta iscrizione',
            html: `
<p>La presente per comunicarle che la sua richiesta di iscrizione come <span style="text-decoration: underline;"><em>Socio Ordinario</em></span> presso l'<em>Associazione di Promozione Sociale "<strong>THE PLACE</strong>"</em> &egrave; stata inoltrata correttamente.</p>
<p>Di seguito i dati da lei inseriti.</p>
<blockquote>
<p>Nome: <strong>${nome}</strong><br />Cognome: <strong>${cognome}</strong><br />Codice Fiscale: <strong>${cf}</strong><br />Comune di Nascita: <strong>${comunenascita}</strong><br />Provincia di Nascita: <strong>${provincianascita}</strong><br />Data di Nascita: <strong>${datanascita}</strong><br />Comune di Residenza: <strong>${comuneresidenza}</strong><br />Provincia di Residenza: <strong>${provinciaresidenza}</strong><br />Indirizzo di Residenza: <strong>${indirizzoresidenza}</strong><br />CAP di Residenza: <strong>${capresidenza}</strong><br />Email: <strong>${email}</strong><br />Telefono: <strong>${telefono}</strong></p>
</blockquote>
<p><span style="text-decoration: underline;"><strong>NB</strong></span>: Una volta che il Consiglio Direttivo approver&agrave; la sua richiesta, le sar&agrave; chiesto di versare l'importo della quota associativa annuale e, successivamente, potr&agrave; partecipare alle attivit&agrave; organizzate dall'Associazione e accedere all'area riservata ai soci tramite il link: <a href="https://www.theplace.family/members" target="_blank" rel="noopener">theplace.family/members</a></p>
<p>Cordiali saluti.<br /><em>Il team di The Place</em></p>`,
        }

        emailjs.send('service_l62dnrr', 'template_html', e, 'user_iUdrXsRM1zAkiOihesBEl').then((res) => {

        }).catch((err) => {
        })
    }
}
