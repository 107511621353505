import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import imgDisco from '../imgs/bg-social.PNG'
import {
    FaInstagram, FaLinkedin,
    FaSeedling,
    FaSpotify,
    FaStreetView,
    FaTiktok,
    FaUserTie,
    FaYoutube
} from "react-icons/fa";
import {useEffect, useState} from "react";
import {nanoid} from "nanoid";
import moment from "moment";
import firebase from "firebase/compat";
import Telegram from "telegram-send-message";
import {Navbar} from "./Navbar";
import logolottie from "../imgs/logoWhite.json";
import Lottie from "react-lottie";
import {Footer} from "../Footer";
import {MetaDecorator} from "../utils/MetaDecorator";


export function OnePageHome() {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [messaggio, setMessaggio] = useState('')

    const [result, setResult] = useState('')

    const [playLottie, setPlayLottie] = useState(false)
    useEffect(() => {
        setTimeout(() => setPlayLottie(true), 750)
    }, [])

    const logoLottieOptions = {
        loop: false,
        autoplay: playLottie,
        animationData: logolottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    }

    const [easteregg, setEasteregg] = useState({hover: false, text:''})
    if(easteregg.text==='theplace') window.location.href = 'https://www.theplace.family/VR32NAlhI34zeqQKY9qtXWWgRZEfaRDFX9InFDn0'

    return (
        <div id="home" className="header">
            <MetaDecorator
                title="The PLACE | Associazione Culturale di Promozione Sociale"
                description="The Place è un'associazione culturale di promozione sociale il cui obbiettivo è quello di avvicinare i giovani alla cultura, creando un ponte tra l’individuo, la collettività e l’arte, sensibilizzare verso tematiche sociali ed agire concretamente sul territorio. Per fare ciò è stato scelto un linguaggio universale, quello della musica. Tramite mostre, cortometraggi, video musicali ed eventi verrà valorizzato il patrimonio artistico e naturalistico del paese, dando voce alla comunità. Ogni progetto avrà una forte componente sociale: coinvolgimento di studenti ed associazioni, coinvolgimento del comune e fondazioni museali, ricavi finalizzati alla realizzazione di azioni concrete sul territorio."
                keywords=""
            />
            <Navbar style={{zIndex:99999}} />
            <div id="videowrapperHome">
                <div id="fullScreenDivHome">
                    <video src="https://theplaceaps.000webhostapp.com/video-bg-sito.mp4" autoPlay loop
                           playsInline muted
                           id="videoHome"
                    />
                    <div id="videoMessageHome" className="pt-5">
                        <span style={{position: 'fixed', bottom: 10, right: 10, zIndex: 99999}}>
                            {
                                easteregg.text !== '' ?
                                    <p className="small" style={{opacity: 0.1}}>PSW: {easteregg.text}</p>
                                    :
                                    null
                            }
                        </span>
                        <span
                            className="dropShadow"
                            onClick={() => setEasteregg({...easteregg, hover: true})}
                            onMouseLeave={() => setEasteregg({text: '', hover: false})}
                            onKeyDown={e => {
                                if (easteregg.hover) {
                                    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122)) {
                                        setEasteregg({
                                            ...easteregg,
                                            text: easteregg.text + e.key.toLowerCase()
                                        })
                                    } else {
                                        setEasteregg({
                                            ...easteregg,
                                            text: ''
                                        })
                                    }
                                }
                            }}
                        >
                            <Lottie
                                options={logoLottieOptions}
                                height={300}
                                width={300}
                                loop={false}
                                style={{cursor: 'default'}}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div>
                {
                    /**

                     <div className="d-flex justify-content-center align-items-center pt-5" style={{height:'100vh',
                backgroundImage:`url(${ImgBG})`, backgroundSize:'cover', backgroundAttachment:'fixed'}}>

                     </div>

                     */
                }
                <div id="about" style={{minHeight:'80vh'}} className="py-5">
                    <Container>
                        <div className="text-center mb-5">
                            <h1 className="fw-bold">Chi siamo</h1>
                        </div>
                        <div className="mb-5">
                            <h2 className="fw-bold mb-2">
                                <FaSeedling
                                    color="#fff"
                                />
                                &nbsp; Il progetto</h2>
                            <p className="text-justify">
                                The Place nasce Associazione di Promozione Sociale con una spiccata valenza culturale.
                                Il suo progetto si concretizza nella volontà di voler avvicinare i giovani alla cultura, mirando alla sensibilizzazione verso la valorizzazione del territorio e verso tematiche artistiche di diverso genere e tipologia.
                                Questo si traduce nell’organizzazione di eventi e nella creazione di contenuti musicali, oltre a diverse iniziative all’interno dell’Associazione stessa.
                                The Place vuole perseguire i suoi obbiettivi culturali in ogni piccolo dettaglio, a partire dai luoghi scelti, dall’ambientazione e dai contesti musicali.
                            </p>
                            <Link to="/progetti">
                                <Button
                                    variant="danger"
                                    className="rounded10 mt-3"
                                >
                                    Scopri le iniziative &rarr;
                                </Button>
                            </Link>
                        </div>
                        <div className="mb-5">
                            <h2 className="fw-bold mb-2">
                                <FaUserTie
                                    color="#fff"
                                />
                                &nbsp; Chi siamo</h2>
                            <p className="text-justify">
                                Giovani ragazze e ragazzi che durante il loro percorso universitario e professionale hanno voluto dare una forma a quelle che fino a quel momento erano solo passioni o occupazioni secondarie.
                                La componente Sociale di The Place si pone come obbiettivo quello di costruire una grande e folta comunità di personalità diverse che perseguono tutte lo stesso fine: la condivisione e la creazione di tutte le possibili forme d’arte.
                            </p>
                        </div>
                        <div>
                            <h2 className="fw-bold mb-2">
                                <FaStreetView
                                    color="#fff"
                                />
                                &nbsp; Dove siamo</h2>
                            <p className="text-justify">
                                The Place non si vuole localizzare in luogo preciso, ecco perché si vuole mantenere un concetto di dinamicità in ogni evento organizzato e in ogni contenuto creato.
                                Oggi siamo a Torino, domani ci piacerebbe cambiare. Qualche idea? Proponi!
                            </p>
                        </div>
                    </Container>
                </div>
                <div className="bg-danger d-flex justify-content-center align-items-center" style={{minHeight:'30vh'}}>
                    <div className="text-center">
                        <h2 className="fw-bold">Non sei ancora associato?</h2>
                        <p className="mb-4">Diventa socio per partecipare alle attività organizzate dall'Associazione.</p>
                        <Link
                            to="/register"
                        >
                            <Button
                                variant="light"
                                className="rounded10 fw-bold text-uppercase"
                            >
                                Registrati ora
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{height:'70vh', backgroundImage:`url(${imgDisco})`,
                backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', zIndex:-10}}>
                    <div>
                        <h3 className="fw-bold mb-3">Seguici sui <span className="text-decoration-underline">Social</span></h3>
                        <div className="text-center">
                            <a href="https://www.instagram.com/theplace.identity/" target="_blank" rel="noreferrer">
                                <FaInstagram
                                    size="2em"
                                    className="iconSocialOverlay mx-2"
                                    onClick={() => {
                                        firebase.analytics().logEvent('instagram_button', {value:'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.youtube.com/channel/UClgBj1KNsjcBwE3tXWc2mkw" target="_blank" rel="noreferrer">
                                <FaYoutube
                                    size="2em"
                                    className="iconSocialOverlay mx-2"
                                    onClick={() => {
                                        firebase.analytics().logEvent('youtube_button', {value:'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                                <FaTiktok
                                    size="1.75em"
                                    className="iconSocialOverlay mx-2"
                                    onClick={() => {
                                        firebase.analytics().logEvent('tiktok_button', {value:'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.linkedin.com/company/theplaceaps" target="_blank" rel="noreferrer">
                                <FaLinkedin
                                    size="2em"
                                    className="mx-2 iconSocialOverlay"
                                />
                            </a>
                            <a href="https://open.spotify.com/user/ivd0hxwku497zwo2ephm52p1y?si=714a87484d9d4644" target="_blank" rel="noreferrer">
                                <FaSpotify
                                    size="2em"
                                    className="iconSocialOverlay mx-2"
                                    onClick={() => {
                                        firebase.analytics().logEvent('spotify_button', {value:'true'})
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div id="contacts" className="pt-5 pb-5 pb-md-0">
                    <Container>
                        <div className="text-center mb-5">
                            <h1 className="fw-bold">Contattaci</h1>
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} md={6} className="text-center px-5 py-5">
                                    <p className="mb-3">Le idee sono veramente tante. <br/> Per realizzarle abbiamo bisogno dell'aiuto di tutti!</p>
                                    <p className="mb-3">Vogliamo creare un collettivo di musicisti, grafici, pittori, fotografi, appassionati d'arte in ogni sua forma. Vogliamo poter dare a tutti la possibilità di mostrare le proprie opere, di esibirsi, di conoscere e di farsi conoscere.</p>
                                    <p>Hai qualcosa da proporre? <br/> Compila il form o inviaci una mail.</p>
                                </Col>
                                <Col xs={12} md={6} className="px-5">
                                    <Form.Group
                                        className="mb-2"
                                    >
                                        <Form.Label className="small m-1">
                                            Nome e Cognome *
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="formContacts"
                                            name="nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-2"
                                    >
                                        <Form.Label className="small m-1">
                                            Email *
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            className="formContacts"
                                            name="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                    >
                                        <Form.Label className="small m-1">
                                            Messaggio * <span className="opacity-50 fw-light">&emsp;&emsp; massimo 500 caratteri</span>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            maxLength={500}
                                            rows={5}
                                            className="formContacts"
                                            name="messaggio"
                                            value={messaggio}
                                            onChange={e => setMessaggio(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="text-end"
                                    >
                                <span className="fw-bold mx-3">
                                    {result}
                                </span>
                                        <Button
                                            variant="light"
                                            className="rounded10"
                                            disabled={nome.trim()==='' || !validateEmail(email) || messaggio.trim()===''}
                                            onClick={() => {
                                                const id = nanoid(10)
                                                const data = moment().format('YYYY-MM-DD H:mm')
                                                firebase.firestore().collection('formContattaci').doc(id).set({
                                                    id: id,
                                                    data: data,
                                                    nome: nome,
                                                    email: email,
                                                    messaggio: messaggio,
                                                }).then(() => {
                                                    setNome('')
                                                    setEmail('')
                                                    setMessaggio('')
                                                    sendTelegramMessage({data, nome, email, messaggio})
                                                    setResult('Messaggio inviato con successo.')
                                                }).catch(() => {
                                                    setResult('Errore, riprova più tardi.')
                                                })
                                            }}
                                        >
                                            Invia
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        </div>
    )

    function validateEmail(emailAdress)
    {
        let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return !!emailAdress.match(regexEmail);
    }

    function sendTelegramMessage(props) {
        const str = `NUOVO MESSAGGIO - FORM CONTATTACI - Nome: ${props.nome} - Email: ${props.email} - Messaggio: ${props.messaggio} - Data: ${props.data}`
        Telegram.setToken('5245347041:AAFA60E6hBwdSWUJNiRLT5Nq_XCUcYswUFo')
        Telegram.setRecipient('325275429')
        Telegram.setMessage(str)
        Telegram.send()
    }
}
