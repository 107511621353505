import {Container} from "react-bootstrap";
import logoWhite from "./logo/logo_white.png";
import {FaInstagram, FaLinkedin, FaSpotify, FaTiktok, FaYoutube} from "react-icons/fa";
import firebase from "firebase/compat";
import {Link} from "react-router-dom";


export function Footer() {
    return (
        <div>
            <Container className="d-block d-md-none py-5 text-light text-center">
                <Container className="opacity-25">
                    <div className="mb-4">
                        <img
                            src={logoWhite}
                            height={70}
                            alt="logoThePlace"
                            className="mb-3"
                        />
                        <div>
                            <h5 className="fw-bold mb-2">The Place APS</h5>
                            <p className="text-uppercase small mb-2">Associazione Culturale<br/>di Promozione Sociale</p>
                            <p className="fw-light" style={{fontSize: '0.75em'}}>CF: 97872150012</p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1">
                            <a href="https://www.instagram.com/theplace.identity/" target="_blank" rel="noreferrer">
                                <FaInstagram
                                    size="1.2em"
                                    className="iconSocialOverlay"
                                    onClick={() => {
                                        firebase.analytics().logEvent('instagram_button', {value: 'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.youtube.com/channel/UClgBj1KNsjcBwE3tXWc2mkw" target="_blank"
                               rel="noreferrer">
                                <FaYoutube
                                    size="1.2em"
                                    className="iconSocialOverlay mx-2"
                                    onClick={() => {
                                        firebase.analytics().logEvent('youtube_button', {value: 'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                                <FaTiktok
                                    size="1em"
                                    className="iconSocialOverlay"
                                    onClick={() => {
                                        firebase.analytics().logEvent('tiktok_button', {value: 'true'})
                                    }}
                                />
                            </a>
                            <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                                <FaLinkedin
                                    size="1.2em"
                                    className="iconSocialOverlay mx-2"
                                />
                            </a>
                            <a href="https://open.spotify.com/user/ivd0hxwku497zwo2ephm52p1y?si=714a87484d9d4644"
                               target="_blank" rel="noreferrer">
                                <FaSpotify
                                    size="1.2em"
                                    className="iconSocialOverlay"
                                    onClick={() => {
                                        firebase.analytics().logEvent('spotify_button', {value: 'true'})
                                    }}
                                />
                            </a>
                        </div>
                        <div style={{fontSize: '0.75em'}}>
                            <a href="mailto:developer@theplace.family" target="_blank" rel="noreferrer"
                               className="link-underline">developer@theplace.family</a>
                        </div>
                        <div style={{fontSize: '0.75em'}}>
                            <Link to="/privacy" className="link-underline">Privacy e Cookie Policy</Link>
                        </div>
                    </div>
                </Container>
            </Container>



            <Container className="d-none d-md-block py-5 text-light text-center">
                <Container>
                    <div className="d-flex justify-content-evenly align-items-center opacity-25">
                        <div className="d-flex justify-content-center align-items-center">
                            <div>
                                <img
                                    src={logoWhite}
                                    height={70}
                                    alt="logoThePlace"
                                    className="mx-4"
                                />
                            </div>
                            <div className="text-start">
                                <div>
                                    <h5 className="fw-bold">The Place APS</h5>
                                    <p className="text-uppercase small mb-2">Associazione Culturale di Promozione
                                        Sociale</p>
                                    <p className="fw-light" style={{fontSize: '0.75em'}}>CF: 97872150012</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="mb-1">
                                <a href="https://www.instagram.com/theplace.identity/" target="_blank" rel="noreferrer">
                                    <FaInstagram
                                        size="1.2em"
                                        className="iconSocialOverlay"
                                        onClick={() => {
                                            firebase.analytics().logEvent('instagram_button', {value: 'true'})
                                        }}
                                    />
                                </a>
                                <a href="https://www.youtube.com/channel/UClgBj1KNsjcBwE3tXWc2mkw" target="_blank"
                                   rel="noreferrer">
                                    <FaYoutube
                                        size="1.2em"
                                        className="iconSocialOverlay mx-2"
                                        onClick={() => {
                                            firebase.analytics().logEvent('youtube_button', {value: 'true'})
                                        }}
                                    />
                                </a>
                                <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                                    <FaTiktok
                                        size="1em"
                                        className="iconSocialOverlay"
                                        onClick={() => {
                                            firebase.analytics().logEvent('tiktok_button', {value: 'true'})
                                        }}
                                    />
                                </a>
                                <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                                    <FaLinkedin
                                        size="1em"
                                        className="iconSocialOverlay mx-2"
                                    />
                                </a>
                                <a href="https://open.spotify.com/user/ivd0hxwku497zwo2ephm52p1y?si=714a87484d9d4644"
                                   target="_blank" rel="noreferrer">
                                    <FaSpotify
                                        size="1.2em"
                                        className="iconSocialOverlay"
                                        onClick={() => {
                                            firebase.analytics().logEvent('spotify_button', {value: 'true'})
                                        }}
                                    />
                                </a>
                            </div>
                            <div style={{fontSize: '0.75em'}}>
                                <a href="mailto:developer@theplace.family" target="_blank" rel="noreferrer"
                                   className="link-underline">developer@theplace.family</a>
                            </div>
                            <div style={{fontSize: '0.75em'}}>
                                <Link to="/privacy" className="link-underline">Privacy e Cookie Policy</Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </div>
)
}


/**

    <Container className="d-none d-md-block py-5 text-light">
        <Container className="d-flex justify-content-center align-items-center opacity-25">
            <Row>
                <Col md="auto" className="text-end">
                    <img

                        src={logoWhite}
                        height={100}
                        alt="logoThePlace"
                    />
                </Col>
                <Col className="text-start">
                    <h3 className="fw-bold">The Place</h3>
                    <p className="small text-uppercase opacity-75 fw-light mb-2">Associazione culturale di promozione
                        sociale</p>
                    <div className="mb-1">
                        <a href="https://www.instagram.com/theplace.identity/" target="_blank" rel="noreferrer">
                            <FaInstagram
                                size="1.2em"
                                className="iconSocialOverlay"
                                onClick={() => {
                                    firebase.analytics().logEvent('instagram_button', {value: 'true'})
                                }}
                            />
                        </a>
                        <a href="https://www.youtube.com/channel/UClgBj1KNsjcBwE3tXWc2mkw" target="_blank"
                           rel="noreferrer">
                            <FaYoutube
                                size="1.2em"
                                className="iconSocialOverlay mx-2"
                                onClick={() => {
                                    firebase.analytics().logEvent('youtube_button', {value: 'true'})
                                }}
                            />
                        </a>
                        <a href="https://www.tiktok.com/@theplace.identity?" target="_blank" rel="noreferrer">
                            <FaTiktok
                                size="1em"
                                className="iconSocialOverlay"
                                onClick={() => {
                                    firebase.analytics().logEvent('tiktok_button', {value: 'true'})
                                }}
                            />
                        </a>
                        <a href="https://open.spotify.com/user/ivd0hxwku497zwo2ephm52p1y?si=714a87484d9d4644"
                           target="_blank" rel="noreferrer">
                            <FaSpotify
                                size="1.2em"
                                className="iconSocialOverlay mx-2"
                                onClick={() => {
                                    firebase.analytics().logEvent('spotify_button', {value: 'true'})
                                }}
                            />
                        </a>
                    </div>
                    <div style={{fontSize: '0.75em'}}>
                        <a href="mailto:developer@theplace.family" target="_blank"
                           rel="noreferrer">developer@theplace.family</a>
                    </div>
                    <div style={{fontSize: '0.75em'}}>
                        <Link to="/privacy">Privacy e Cookie Policy</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>


*/
