import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import {Router} from "./Router";
import firebase from "firebase/compat";
import {AuthProvider} from "./utils/Auth";
import {CookiesBanner} from "./utils/CookiesBanner";


if(!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyBFXxZp2s563EJ2dLqu_zXQnQhu0BD3Q2c",
        authDomain: "theplace-identity.firebaseapp.com",
        projectId: "theplace-identity",
        storageBucket: "theplace-identity.appspot.com",
        messagingSenderId: "649052405550",
        appId: "1:649052405550:web:a31a9b8cf625dbb8e984bf",
        measurementId: "G-GNV5ENB9G4",
    })
}
firebase.analytics()


function App() {

    return (
        <div className="App">
            <AuthProvider>
                {
                    !localStorage.getItem('cookies-consent-theplace') ?
                        <CookiesBanner />
                        :
                        null
                }
                <Router />
            </AuthProvider>
        </div>
    );
}

export default App;
