import {SiEgghead} from "react-icons/si";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FaAngleRight} from "react-icons/fa";
import {useState} from "react";
import Telegram from "telegram-send-message";
import moment from "moment";


export function EasterEgg() {

    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [result, setResult] = useState(false)

    if(result) {
        setTimeout(() => {
            window.location.replace('https://www.theplace.family/')
        }, 5000)
    }

    return (
        <div className="App d-flex justify-content-center align-items-center text-center">
            <div>
                <SiEgghead
                    size="8em"
                    className="mb-3"
                />
                <h1 className="fw-bold">Complimenti !</h1>
                <h5 className="fw-normal">Hai trovato l'Easter Egg</h5>
                <div className="mt-5">
                    <Form.Label className="small text-center">Inserisci la tua mail per ricevere il tuo premio.</Form.Label>
                    <Row>
                        <Col className="px-1">
                            <Form.Control
                                type="email"
                                className="rounded10 text-light"
                                style={{backgroundColor:'#000'}}
                                placeholder="email@dominio.com"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                disabled={result}
                            />
                        </Col>
                        <Col md="auto" className="px-1">
                            <Button
                                className="rounded10"
                                variant="light"
                                disabled={email.trim()==='' || result}
                                onClick={() => {
                                    if(validateEmail(email)) {
                                        setError('Email non valida.')
                                    } else {
                                        setError('')
                                        sendTelegramMessage({email:email})
                                        setResult(true)
                                    }
                                }}
                            >
                                <FaAngleRight />
                            </Button>
                        </Col>
                    </Row>
                    {
                        error ?
                            <p className="text-center text-danger small fw-bold mt-3">{error}</p>
                            :
                            null
                    }
                    {
                        result ?
                            <div className="mt-3">
                                <h1 className="mb-2">🎉🎉</h1>
                                <p className="text-center fw-bold mb-2">Fatto! Verrai contattato per il tuo premio!</p>
                                <p className="text-center small opacity-50">Ti stiamo reindirizzando alla Home...</p>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )

    function validateEmail(emailAdress)
    {
        let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return !emailAdress.match(regexEmail);
    }

    function sendTelegramMessage(props) {
        const str = `🥚 EASTER EGG 🥚 - Trovato il ${moment().format('DD/MM/YYYY')} alle ore ${moment().format('H:mm')} da ${props.email}`
        Telegram.setToken('5245347041:AAFA60E6hBwdSWUJNiRLT5Nq_XCUcYswUFo')
        Telegram.setRecipient('325275429')
        Telegram.setMessage(str)
        Telegram.send()
    }
}
