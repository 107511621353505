import {Col, Row} from "react-bootstrap";
import {FaAt, FaBehance, FaFacebook, FaInstagram, FaLink, FaLinkedin, FaTiktok, FaTwitter} from "react-icons/fa";


export function CardOpera({data, flag}) {
    return (

        <div id={getId(data.titolo, data.autore)}>
            <Row className={flag ? 'flex-row-reverse' : ''}>
                <Col xs={12} lg={6} className="mb-5 mb-lg-0 px-4 d-flex justify-content-center align-items-center">
                    {
                        data.url!=='' ?
                            <img
                                src={data.url}
                                alt={data.titolo}
                                width="100%"
                            />
                            :
                            null
                    }
                </Col>
                <Col xs={12} lg={6} className="text-center text-lg-start px-5 d-flex justify-content-center align-items-center">
                    <div style={{width:'100%'}}>
                        <div className="mb-5">
                            {
                                data.titolo!=='' ?
                                    <h1 className="fw-bold fst-italic">{data.titolo}</h1>
                                    :
                                    null
                            }
                            {
                                data.autore!=='' ?
                                    <h5 className="fw-light">di <span style={{fontWeight:'600'}}>{data.autore}</span></h5>
                                    :
                                    null
                            }
                        </div>
                        {
                            data.descrizione!=='' ?
                                <div className="mb-5">
                                    <p className="text-justify">
                                        {data.descrizione}
                                    </p>
                                </div>
                                :
                                null
                        }
                        {
                            data.dimensioni==='' && data.anno==='' ?
                                null
                                :
                                <div className="mb-5">
                                    {
                                        data.anno!=='' ?
                                            <p className="small">
                                                <span className="opacity-75">Anno pubblicazione:</span> {data.anno}
                                            </p>
                                            :
                                            null
                                    }
                                    {
                                        data.dimensioni!=='' ?
                                            <p className="small">
                                                <span className="opacity-75">Dimensioni:</span> {data.dimensioni}
                                            </p>
                                            :
                                            null
                                    }
                                </div>
                        }
                        <div>
                            {
                                data.contatti.instagram!=='' ?
                                    <a href={data.contatti.instagram} target="_blank" rel="noreferrer">
                                        <FaInstagram
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.facebook!=='' ?
                                    <a href={data.contatti.facebook} target="_blank" rel="noreferrer">
                                        <FaFacebook
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.twitter!=='' ?
                                    <a href={data.contatti.twitter} target="_blank" rel="noreferrer">
                                        <FaTwitter
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.tiktok!=='' ?
                                    <a href={data.contatti.tiktok} target="_blank" rel="noreferrer">
                                        <FaTiktok
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.behance!=='' ?
                                    <a href={data.contatti.behance} target="_blank" rel="noreferrer">
                                        <FaBehance
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.linkedin!=='' ?
                                    <a href={data.contatti.linkedin} target="_blank" rel="noreferrer">
                                        <FaLinkedin
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.email!=='' ?
                                    <a href={'mailto:' + data.contatti.email} target="_blank" rel="noreferrer">
                                        <FaAt
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                            {
                                data.contatti.website!=='' ?
                                    <a href={data.contatti.website} target="_blank" rel="noreferrer">
                                        <FaLink
                                            size="1.5em"
                                            className="iconHover mx-2"
                                        />
                                    </a>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )

    function getId(a, b) {
        let res = a + '-' + b
        res = res.replace(/\s+/g, '');

        return res
    }

}


export function Opera({data}) {

    const id = window.location.pathname.split('/')[3]
    let info
    if(data.filter(o => o.id===id).length===0) {
        window.location.href = 'https://www.theplace.family/404'
    } else info = data.filter(o => o.id===id)[0]

    return <CardOpera data={info} flag={false} />

}
