import {Navbar} from "../OnePageHome/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import logoTP from "../logo/logo_black.png";
import {FaArrowRight} from "react-icons/all";
import {Link} from "react-router-dom";
import {FaDownload} from "react-icons/fa";
import spotifyComala from '../imgs/comala-spotify.png'
import mappaMostra from '../imgs/mappa_mostra_comala.png'
import {Footer} from "../Footer";
import {MetaDecorator} from "../utils/MetaDecorator";


export function MostraComala() {

    return (
        <div className="pt-5">
            <MetaDecorator
                title="iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <Container fluid className="py-5 text-dark" style={{backgroundColor:'#eeeeee'}}>
                <div className="mb-4 mt-5 text-center">
                    <img
                        src={logoTP}
                        height={100}
                        alt="logoThePlace"
                    />
                </div>
                <div className="text-center">
                    <h2 className="fw-bold"><i>iosonotheplace</i> - vol. 2</h2>
                    <p>Guarda la mostra in versione digitale.</p>
                    <div className="small mt-3">
                        <a href="https://firebasestorage.googleapis.com/v0/b/theplace-identity.appspot.com/o/documenti%2Fmappa_mostra_comala.pdf?alt=media&token=32044de8-df0e-4061-bb94-80108a0fc940" target="_blank" rel="noreferrer"
                           className="text-dark"
                        >
                            <FaDownload />
                            <span>&nbsp;&nbsp;<u>Scarica la mappa della mostra</u></span>
                        </a>
                    </div>
                    <div className="mt-4">
                        <a href="https://open.spotify.com/playlist/5Ry3Ev3EJRZCEeWMsxM1ci?si=e1901ca772594638" target="_blank" rel="noreferrer">
                            <img
                                src={spotifyComala}
                                alt="Playlist Spotify"
                                height={50}
                                className="iconHover"
                            />
                        </a>
                    </div>
                </div>
            </Container>
            <Container className="py-5">
                <Row className="py-5">
                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/spleen">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url('https://i.ibb.co/FnTMdYz/50x70-Spleen3.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Spleen</h3>
                                    <p>Piccolo Inverno</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/corpo">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/RTSZ856/Jonasz-Cieslak-3.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Corpo</h3>
                                    <p>Bar</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/donne">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/1R3dgt4/50x70-Strangeness-Iris-Elsa-Giovale.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Donne</h3>
                                    <p>Scalinata</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/viaggi">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/WNhkdTR/90x200-Alessia-Pezzato-Volc-n-de-Fuego-Guatemala.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Viaggi</h3>
                                    <p>Aula Studio</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/protesta">
                            <div className="text-light cardMostraComala shadowBackdrop" style={{backgroundImage:`url("https://i.ibb.co/xmkrJ4G/DSCF5676.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'bottom', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Protesta</h3>
                                    <p>TensoBar</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/underground">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/sR6CBNC/50x70-Sam-Ficara4.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Underground</h3>
                                    <p>Via delle Spezie</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/urban">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/QrMW3Lj/60x90-Ylenia-Vitolo-Con-me.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Urban</h3>
                                    <p>Via delle Spezie</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/grafiche">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/85H9G4y/60x90-Atra-Bilis2.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Grafiche Digitali</h3>
                                    <p>Via Bixio</p>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col md={4} className="p-3">
                        <Link to="/iosonotheplace-vol-2/extra">
                            <div className="text-light cardMostraComala" style={{backgroundImage:`url("https://i.ibb.co/34qNRv0/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-02.jpg")`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', borderRadius:'20px', padding:'2.5em 4em'}}>
                                <div className="shadow-text">
                                    <h3 className="fw-bold mb-1">Extra</h3>
                                </div>
                                <div className="text-end">
                                    <FaArrowRight
                                        className="opacity-50"
                                        size="3.5em"
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>

                </Row>
                <Row>
                    <Col className="text-center">
                        <div>
                            <div className="text-center mb-4">
                                <h3 className="fw-bold">Mappa della Mostra</h3>
                            </div>
                            <img
                                src={mappaMostra}
                                alt="Mappa mostra Comala"
                                style={{width:'80%'}}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <div className="text-center mb-4">
                                <h3 className="fw-bold">Playlist ufficiale della mostra</h3>
                                <p className="small">Le musiche sono state selezionate direttamente dagli artisti</p>
                            </div>
                            <iframe
                                title="Playlist mostra Comala"
                                src="https://open.spotify.com/embed/playlist/5Ry3Ev3EJRZCEeWMsxM1ci?utm_source=generator"
                                style={{width:'100%', height:'380px', frameBorder:'0', borderRadius:'12px'}}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
