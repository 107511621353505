import {Navbar} from "../OnePageHome/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import {CardOpera, Opera} from "./CardOpera";
import {BrowserRouter} from "react-router-dom";
import {Route, Switch} from "react-router";
import {nanoid} from "nanoid";
import {MetaDecorator} from "../utils/MetaDecorator";


export function Spleen() {

    const opere = [
        {
            id: '001',
            titolo: 'Stati permanenti/Stati provvisori',
            autore: 'Giada La Gioia, Angelo Leonardo',
            descrizione: 'Esistere, abitare, risiedere, svilupparsi, sostenersi, mantenersi, sopravvivere nella società moderna. Che effetto ha il contatto con il mondo esterno quando si scontra con la psiche e l’interiorità umana? In che modo l’introiezione di questi messaggi si riflette su di noi? In che modo fuoriesce? Dolore, oppressione, inadeguatezza, precarietà, dissociazione, rabbia... Un continuo rilascio di sensazioni e stati d’animo, che transitano all’interno della nostra esistenza.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/intropyastudio/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/nLc9f7s/50x70-Spleen1.jpg',
        },
        {
            id: '002',
            titolo: 'Progetto zero',
            autore: 'Marzia Di Palma, Francesco Leone',
            descrizione: 'La collezione "Progetto Zero" del concept brand M4.D1, creato da Marzia Di Palma, racchiude il concetto dell\'essenza che caratterizza ogni essere umano. Nei capi M4.D1 il corpo non incontra mai l\'abito; in quanto tale, è stata studiata una scomposizione modellistica in modo che, ognuno, entrando a contatto con esso, possa eleggere la propria "forma".',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/___m4.d1/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/VtNF2ts/50x70-Spleen2.jpg',
        },
        {
            id: '003',
            titolo: 'La cura',
            autore: 'Francesco Di Sante, Valeria D’Ippolito, Giorgia Cinelli',
            descrizione: 'Primo servizio fotografico targato Spleen Magazine. Ispirazione Arancia Meccanica di Stanley Kubrick per via dei suoi 50 anni dall’uscita. Più precisamente la “Cura Ludovico”, trattamento con cui si induce il paziente, attraverso la visione di immagini e scene violente, a non ripetere più le azioni delittuose di cui si era macchiato. Possiamo creare un parallelismo, in cui sarà la nostra modella ad essere sottoposta alla visione di immagini di quel lato dell’”arte” che detestiamo',
            anno: '2022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/spleenmagazine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/FnTMdYz/50x70-Spleen3.jpg',
        },
        {
            id: '004',
            titolo: 'La cura',
            autore: 'Francesco Di Sante, Valeria D’Ippolito, Giorgia Cinelli',
            descrizione: 'Primo servizio fotografico targato Spleen Magazine. Ispirazione Arancia Meccanica di Stanley Kubrick per via dei suoi 50 anni dall’uscita. Più precisamente la “Cura Ludovico”, trattamento con cui si induce il paziente, attraverso la visione di immagini e scene violente, a non ripetere più le azioni delittuose di cui si era macchiato. Possiamo creare un parallelismo, in cui sarà la nostra modella ad essere sottoposta alla visione di immagini di quel lato dell’”arte” che detestiamo',
            anno: '2022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/spleenmagazine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/5GDGtT0/50x70-Spleen4.jpg',
        },
        {
            id: '005',
            titolo: 'Ri-guardo',
            autore: 'Giorgia Cinelli, Giorgia Cinelli',
            descrizione: 'Chi sono? Non riesco ad etichettarmi in un’unica cosa, un unico pensiero. Ripercorrendo la mia identità, entro nella mia psiche. Viaggio introspettivo | sensazione: distacco, discontinuità, confusione, annullamento, introspezione, connessione, empatia.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/reverieconcept/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/qr50SQd/50x70-Spleen5.jpg',
        },
        {
            id: '006',
            titolo: 'Ri-guardo',
            autore: 'Giorgia Cinelli, Giorgia Cinelli',
            descrizione: 'Chi sono? Non riesco ad etichettarmi in un’unica cosa, un unico pensiero. Ripercorrendo la mia identità, entro nella mia psiche. Viaggio introspettivo | sensazione: distacco, discontinuità, confusione, annullamento, introspezione, connessione, empatia.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/reverieconcept/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/h1sb2tc/50x70-Spleen6.jpg',
        },
        {
            id: '007',
            titolo: 'Stati permanenti/Stati provvisori',
            autore: 'Giada La Gioia, Angelo Leonardo',
            descrizione: 'Esistere, abitare, risiedere, svilupparsi, sostenersi, mantenersi, sopravvivere nella società moderna. Che effetto ha il contatto con il mondo esterno quando si scontra con la psiche e l’interiorità umana? In che modo l’introiezione di questi messaggi si riflette su di noi? In che modo fuoriesce? Dolore, oppressione, inadeguatezza, precarietà, dissociazione, rabbia... Un continuo rilascio di sensazioni e stati d’animo, che transitano all’interno della nostra esistenza.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/intropyastudio/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/N7CQkb8/50x70-Spleen7.jpg',
        },
        {
            id: '008',
            titolo: 'Progetto zero',
            autore: 'Marzia Di Palma, Francesco Leone',
            descrizione: 'La collezione "Progetto Zero" del concept brand M4.D1, creato da Marzia Di Palma, racchiude il concetto dell\'essenza che caratterizza ogni essere umano. Nei capi M4.D1 il corpo non incontra mai l\'abito; in quanto tale, è stata studiata una scomposizione modellistica in modo che, ognuno, entrando a contatto con esso, possa eleggere la propria "forma".',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/___m4.d1/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.spleenmag.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/GJzcvLm/50x70-Spleen8.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Spleen | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/spleen">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'70vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/FnTMdYz/50x70-Spleen3.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <img
                                    src="https://www.spleenmag.com/wp-content/uploads/2021/04/logo_b.png"
                                    height={80}
                                    alt="logo Spleen"
                                    className="mb-4"
                                />
                                <p>Piccolo inverno</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={nanoid(8)}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>

                    <Route path="/iosonotheplace-vol-2/spleen">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#ff0000', backgroundImage:`url('https://i.ibb.co/FnTMdYz/50x70-Spleen3.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}
                            >
                                <div className="text-center">
                                    <img
                                        src="https://www.spleenmag.com/wp-content/uploads/2021/04/logo_b.png"
                                        height={80}
                                        alt="logo Spleen"
                                        className="mb-4"
                                    />
                                    <p>Piccolo inverno</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Corpo() {

    const opere = [
        {
            id: '001',
            titolo: 'Presa d\'atto dell\'esistenza di un corpo - 2',
            autore: 'Francesca Migliorin',
            descrizione: 'L’immagine appartiene a una serie fotografica più ampia, dove la fotografa si ridefinisce fisicamente. Questa serie pone il focus sul proprio corpo, l’autoscatto diventa quindi una performance tramite la quale l’autrice acquisisce la consapevolezza finale di vivere in un fisico concreto, che però non si è mai davvero voluto e apprezzato. Ne riconosce le forme con le mani, toccando il proprio derma e la propria carne, definendo e sottolinenando i difetti che l’hanno sempre fatta sentire inidonea. La pelle viene ricoperta da argilla che, fatta asciugare, si sgretola evidenziandone le pieghe in base a come il corpo viene modellato; inoltre viene usata per descrivere la condizione di pelle secca, irritata, a volte arrossata e che tira di cui soffre l’artista. La serie vuole essere quindi un percorso di conoscenza all’interno del proprio involucro, di incontro verso il proprio io, di consapevolezza della propria esistenza e del proprio corpo. Un’istantanea meticolosa su quello che si è e che si è vissuto.',
            anno: '2022',
            dimensioni: '43x50 cm',
            contatti: {
                instagram: 'https://www.instagram.com/ziska.raw',
                email: 'f.migliorin98@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/hVV7zsV/2-Francesca-Migliorin-min.jpg',
        },
        {
            id: '002',
            titolo: 'Presa d\'atto dell\'esistenza di un corpo - 3',
            autore: 'Francesca Migliorin',
            descrizione: 'L’immagine appartiene a una serie fotografica più ampia, dove la fotografa si ridefinisce fisicamente. Questa serie pone il focus sul proprio corpo, l’autoscatto diventa quindi una performance tramite la quale l’autrice acquisisce la consapevolezza finale di vivere in un fisico concreto, che però non si è mai davvero voluto e apprezzato. Ne riconosce le forme con le mani, toccando il proprio derma e la propria carne, definendo e sottolinenando i difetti che l’hanno sempre fatta sentire inidonea. La pelle viene ricoperta da argilla che, fatta asciugare, si sgretola evidenziandone le pieghe in base a come il corpo viene modellato; inoltre viene usata per descrivere la condizione di pelle secca, irritata, a volte arrossata e che tira di cui soffre l’artista. La serie vuole essere quindi un percorso di conoscenza all’interno del proprio involucro, di incontro verso il proprio io, di consapevolezza della propria esistenza e del proprio corpo. Un’istantanea meticolosa su quello che si è e che si è vissuto.',
            anno: '2022',
            dimensioni: '46x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/ziska.raw',
                email: 'f.migliorin98@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/d0QP4dF/3-Francesca-Migliorin-min.jpg',
        },
        {
            id: '003',
            titolo: 'Body Discovery',
            autore: 'Luca Iuliani',
            descrizione: 'L\'opera nasce ai tempi della prima quarantena, quando un contatto così inevitabile e diretto con me stesso mi ha condotto all\'esplorazione del mio stesso corpo in tutti i suoi aspetti, compresi quelli sessuali, ed anche all\'accettazione di essi. Alla base di questo lavoro c\'è la presa di consapevolezza del proprio corpo, a tal punto da elogiare e ritenere erotiche parti che ordinariamente non consideriamo, delle volte anzi le odiamo.',
            anno: '2022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/Luca_iuliani_',
                email: 'Lucaiuliani2018@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/2ZVBKhP/50x70-Luca-Iuliani-Body-discovery-2.jpg',
        },
        {
            id: '004',
            titolo: 'Radici',
            autore: 'Elisa Scarduelli',
            descrizione: '',
            anno: '2022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'elisa.scarduelli@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/94hKJwX/001415150002.jpg',
        },
        {
            id: '005',
            titolo: 'Exist for Love',
            autore: 'Dalila De Luca',
            descrizione: 'Exist for love vuole portare lo spettatore all\'interno di una dimensione primordiale, all\'essenziale, ad una riconnessione con il proprio io interiore e dunque con la Natura. Rivela soggetti messi totalmente a nudo, esseri viventi in armonia tra di loro che incarnano la nascita, la sessualità, la fragilità e la forza al tempo stesso. Riunificate con la loro sostanza istintuale e originaria, queste anime arcaiche non prevaricano sul paesaggio, ma ne sono esse stesse parte integrante e creatrice, corpi genuini che spogliati da preconcetti e stereotipi comunicano con le loro posture la ricerca dell\'essenza di tutte le cose. Ci invita così ad una serie di riflessioni e ad un più alto livello di consapevolezza su temi quali diversità, unicità e accettazione.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/daliladeluca.ph',
                email: 'deluca94@hotmail.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/ZSxYHYt/Dalila-de-Luca-EXIST-FOR-LOVE.jpg',
        },
        {
            id: '006',
            titolo: 'Tutti i miei timori sparirono',
            autore: 'Dalila De Luca',
            descrizione: 'Questa immagine vuole portare lo spettatore all\'interno di una dimensione primordiale, all\'essenziale, ad una riconnessione con il proprio io interiore e dunque con la Natura. Rivela soggetti messi totalmente a nudo, esseri viventi in armonia tra di loro che incarnano la nascita, la sessualità, la fragilità e la forza al tempo stesso. Riunificate con la loro sostanza istintuale e originaria, queste anime arcaiche non prevaricano sul paesaggio, ma ne sono esse stesse parte integrante e creatrice, corpi genuini che spogliati da preconcetti e stereotipi comunicano con le loro posture la ricerca dell\'essenza di tutte le cose.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/daliladeluca.ph',
                email: 'deluca94@hotmail.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/wM5CmMG/Dalila-de-Luca3.jpg',
        },
        {
            id: '007',
            titolo: 'Immersion',
            autore: 'Kateryna Zhydkova',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/kate_zhydkova',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/St6kQJK/Immersion.jpg',
        },
        {
            id: '008',
            titolo: 'Embrione • SIMBIOSI',
            autore: 'Marta Pastore',
            descrizione: '',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/mphosgraphe',
                email: 'mpphotography.mailbox@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/sQP6Gqx/50x70-Marta-Pastore.jpg',
        },
        {
            id: '009',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/wYGqKW2/50x70-Sam-Ficara.jpg',
        },
        {
            id: '010',
            titolo: 'Untitled',
            autore: 'Claudia Lo Stimolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/klod.photography',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/0YGYPHn/50x70-claudia-lo-stimolo.jpg',
        },
        {
            id: '011',
            titolo: 'Untitled',
            autore: 'Eugenia Timoshenko',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/nkmMwMS/50x70-Eugenia-Timoshenko3.jpg',
        },
        {
            id: '012',
            titolo: 'Untitled',
            autore: 'Claudia Lo Stimolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/klod.photography',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Qp4KY8P/50x70claudia-lo-stimolo.jpg\n',
        },
        {
            id: '013',
            titolo: 'Body',
            autore: 'Kateryna Zhydkova',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/kate_zhydkova',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/JRrXG4L/Body.jpg',
        },
        {
            id: '014',
            titolo: 'Untitled',
            autore: 'Jonasz Cieslak',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jona.cieslak',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/RTSZ856/Jonasz-Cieslak-3.jpg',
        },
        {
            id: '015',
            titolo: 'Scopri(r)mi',
            autore: 'Rebecca Miglino',
            descrizione: 'È un malinconico tramonto quello che ci porta a fare i conti con noi stessi nei momenti più bui e pesanti. Ilaria è timida, riservata, a volte ha paura di lasciarsi andare agli altri o di dire le cose per quelle che sono. Questa foto fa parte di un progetto nato per caso, un’estate in Sardegna, da uno sguardo di complicità e dal desiderio di accettarsi, comunque siamo e come siamo. I nostri vestiti sono scudi e liberarsene può far male. Riconosco l’incertezza di ognuno di noi nelle linee del suo corpo, quasi spoglio di paure ma ancora stretto tra le corde delle parole che l’hanno colpito fin dal suo primo sole.',
            anno: '2020',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/reby.48/',
                email: 'miglinorebecca@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: 'https://www.tiktok.com/@85mmofworld',
                behance: '',
            },
            url: 'https://i.ibb.co/rfKQc6x/60x90-Rebecca-Miglino.jpg',
        },
        {
            id: '016',
            titolo: 'Corpo',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'Abbi buona cura del tuo corpo, è l’unico posto in cui devi vivere.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/W3swFdw/PARTI-DEL-CORPO.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Corpo | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/corpo">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/RTSZ856/Jonasz-Cieslak-3.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Corpo</h1>
                                <p>Bar</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/corpo">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/RTSZ856/Jonasz-Cieslak-3.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Corpo</h1>
                                    <p>Bar</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>

        </div>
    )
}

export function Donne() {

    const opere = [
        {
            id: '001',
            titolo: 'Hidden Inner',
            autore: 'Iris Elsa Giovale',
            descrizione: 'Quest’opera mostra una ragazza con due teste, a rappresentare la doppia identità che si trova dentro ognuno di noi; come già Stevenson aveva narrato in Dr. Jekyll e Mr. Hyde, tutti noi abbiamo un buono e un cattivo, più precisamente qui, una faccia che mostriamo a tutti e un’altra che custodisce ogni nostro pensiero più profondo.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/feuer.photo_',
                email: 'iris.giovale@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/2N61J9h/50x70-Hidden-Inner-Iris-Elsa-Giovale.jpg',
        },
        {
            id: '002',
            titolo: 'Strangeness',
            autore: 'Iris Elsa Giovale',
            descrizione: 'Questo progetto comprendo più opere, qui ne vedete due esposte:\n- una mostra una donna con in mano uno specchio, che non riflette ciò che c’è davanti al soggetto, ma mostra cosa c’è all’interno della mente della donna, ovvero due mani che coprono un volto inesistente; la chiave di lettura è personale, ma per me simboleggia le insicurezze legate ai canoni di bellezza della società e la voglia di nascondersi;\n- l’altra opera mostra la stessa donna con un bicchiere in mano, lei sembra guardare dritto davanti a sé, ma il bicchiere, sempre come metafora di specchio dell’anima, rivela uno sguardo assente che guarda in un punto non preciso; anche qui le interpretazioni sono numerose, ma per me simboleggia la dissociazione dal proprio corpo.\n\nModella: Martina Vergnano Menzio',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/feuer.photo_',
                email: 'iris.giovale@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/1R3dgt4/50x70-Strangeness-Iris-Elsa-Giovale.jpg',
        },
        {
            id: '003',
            titolo: 'Ritratto di Isha',
            autore: 'Carla Vivalda',
            descrizione: 'Isuru è venuto in Italia dallo Sri Lanka quand\'era molto piccolo. Prima di venire qui, non sapeva nemmeno cosa fossero gli omosessuali. In Sri Lanka la comunità LGBTQ+ è praticamente invisibile e senza diritti di alcun tipo. Gli uomini gay vengono puniti con dieci anni di carcere perché l"omosessualità è ancora illegale dopo tanti anni di tentativi di decriminalizzazione. Solo in Italia Isuru è entrato in contatto con la sua identità sessuale e con una realtà completamente nuova. Ha cominciato ad interessarsi al mondo delle drag queen, senza nascondere la cosa ai genitori. Alla mia domanda su come avessero reagito i suoi dopo aver scoperto che faceva la drag, Isuru mi ha risposto "Perché per loro è come travestirsi in maniera giocosa, un intrattenimento fine a sé stesso". Non perché è proprio attraverso il suo "travestimento" che Isuru rende finalmente esplicito il suo lato femminile, e quindi parte della propria identità. Così ho voluto fotografarlo nelle vesti di Isha e dare sfogo la sua parte più femminile e fiera di chi con i tacchi ci andrebbe anche a lavoro. L\'opera è una fotografia analogica manipolata con il procedimento della film soup (i cui ingredienti sono birra e curcuma) ed è stato stampato su carta fotografica opaca. Il ritratto fa parte di una serie di fotografie dal titolo "What makes you a girl, what makes you a boy" basata sulle tematiche di genere.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/blue_clem',
                email: 'carlavivalda.ph@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: 'https://www.linkedin.com/in/carla-vivalda-5164501b5',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Cb5v8Sm/Carla-Vivalda3.jpg',
        },
        {
            id: '004',
            titolo: 'Strangeness',
            autore: 'Iris Elsa Giovale',
            descrizione: 'Questo progetto comprendo più opere, qui ne vedete due esposte:\n- una mostra una donna con in mano uno specchio, che non riflette ciò che c’è davanti al soggetto, ma mostra cosa c’è all’interno della mente della donna, ovvero due mani che coprono un volto inesistente; la chiave di lettura è personale, ma per me simboleggia le insicurezze legate ai canoni di bellezza della società e la voglia di nascondersi;\n- l’altra opera mostra la stessa donna con un bicchiere in mano, lei sembra guardare dritto davanti a sé, ma il bicchiere, sempre come metafora di specchio dell’anima, rivela uno sguardo assente che guarda in un punto non preciso; anche qui le interpretazioni sono numerose, ma per me simboleggia la dissociazione dal proprio corpo.\n\nModella: Martina Vergnano Menzio',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/feuer.photo_',
                email: 'iris.giovale@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/CWCQG6y/50x70-Strangeness-Iris-Elsa-Giovale-4.jpg',
        },
        {
            id: '005',
            titolo: 'UtR',
            autore: 'Andrea Simeone',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/andrea___sim',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/NY3LGML/50x70andrea-simeone.jpg',
        },
        {
            id: '006',
            titolo: 'Reborn',
            autore: 'Andrea Simeone',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/andrea___sim',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Kx42z8y/50x70-Andrea-Simeone.jpg',
        },
        {
            id: '007',
            titolo: 'Serie 2',
            autore: 'Carolina Barbero',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/carolinabarberoph',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/gw69Bt0/50x70carolina-barbero.jpg',
        },
        {
            id: '008',
            titolo: 'Untitled',
            autore: 'Eugenia Timoshenko',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/TgxgkMD/Eugenia-Timoshenko-1-42x30.jpg',
        },
        {
            id: '009',
            titolo: 'Untitled',
            autore: 'Fortuna De Nardo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/fortunadenardo',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/4J7XsNr/Fortuna-De-Nardo-1.jpg',
        },
        {
            id: '010',
            titolo: 'Greta, novembre 2021',
            autore: 'Greta Guiotto',
            descrizione: 'In questo scatto è rappresentata Greta, una mia grande amica. Non c’è molto da descrivere, se non che, quando ho scattato questa foto, mi sembrava che combaciasse tutto alla perfezione: il suo sguardo, le luci, la posa. Un quadretto che secondo me, è in piena armonia e linearità.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/greta.guiotto',
                email: 'greta.guiotto@edu.unito.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/KXsbd9t/Greta-Guiotto-novembre-2021.jpg',
        },
        {
            id: '011',
            titolo: 'Untitled',
            autore: 'Greta Guiotto',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/greta.guiotto',
                email: 'greta.guiotto@edu.unito.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/FsV91R6/greta-guiotto-1.jpg',
        },
        {
            id: '012',
            titolo: 'Daniela, ottobre 2020',
            autore: 'Greta Guiotto',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/greta.guiotto',
                email: 'greta.guiotto@edu.unito.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/BPKQPYm/greta-guiotto.jpg',
        },
        {
            id: '013',
            titolo: 'Untitled',
            autore: 'Eugenia Timoshenko',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/P4hNcWm/50x70-Eugenia-Timoshenko4.jpg',
        },
        {
            id: '014',
            titolo: 'Rick Donne',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'Le donne hanno sempre dovuto lottare doppiamente. Hanno sempre dovuto portare due pesi, quello privato e quello sociale. Le donne sono la colonna vertebrale delle società.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/M7wP084/DONNE.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Donne | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/donne">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/1R3dgt4/50x70-Strangeness-Iris-Elsa-Giovale.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Donne</h1>
                                <p>Scalinata</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/donne">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/1R3dgt4/50x70-Strangeness-Iris-Elsa-Giovale.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Donne</h1>
                                    <p>Scalinata</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Viaggi() {

    const opere = [
        {
            id: '001',
            titolo: 'Pescatori sul lago Inle',
            autore: 'Romanelli Giulia',
            descrizione: 'I pescatori sul lago Inle utilizzano il piede per remare in modo tale da poter sorreggere le reti con la mano.',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/_astheocean_',
                email: 'giulia.romanelli21@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/P5kgSD1/DSC-0254.jpg',
        },
        {
            id: '002',
            titolo: 'Donna birmana',
            autore: 'Romanelli Giulia',
            descrizione: 'In Myanmar le donne tessono il loto, ricavandone tessuti pregiati e rarissimi. Questa donna, nel suo momento di pausa, fuma il cheroot, tipico sigaro birmano.',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/_astheocean_',
                email: 'giulia.romanelli21@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/0JtgVCD/DSC-0328.jpg',
        },
        {
            id: '003',
            titolo: 'Monaci',
            autore: 'Romanelli Giulia',
            descrizione: 'Samanera, il giorno della luna piena di Pyatho. Nell’ Ananda Pagoda a Bagan, migliaia di monaci si riuniscono in lunghe file attendendo offerte sin dalle prima luci dell’alba.',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/_astheocean_',
                email: 'giulia.romanelli21@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/9rtkNBr/DSC-0039.jpg',
        },
        {
            id: '004',
            titolo: 'The child',
            autore: 'Romanelli Giulia',
            descrizione: 'Durante le fiere locali i bambini giocano dei carri bestiame.',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/_astheocean_',
                email: 'giulia.romanelli21@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/09ssRPz/DSC-0069.jpg',
        },
        {
            id: '005',
            titolo: 'La buena onda',
            autore: 'Tommaso Cherubini',
            descrizione: 'Ho scattato questa foto a San Pedro de Atacama, nel deserto all\'estremo nord del Chile. Un gruppo di indigeni vestito con i loro abiti tradizionali ha improvvisato una danza in una piazzetta della città. Hanno suonato, cantato e ballato mentre si formava un grande cerchio intorno a loro. Le popolazioni indigene sono in grande diminuzione a causa della globalizzazione, dello sfruttamento e della pandemia. Sono ormai molto poche le tribù che rimangono fedeli alle proprie tradizioni senza essere mangiate dal progresso tecnologico e dall\'invasione dei grandi proprietari terrieri.',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tomminicherubaso',
                email: 'cherubini.tommaso@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/PrMyk9h/60x90-Tommaso-Cherubini2.jpg',
        },
        {
            id: '006',
            titolo: 'Volcán de Fuego, Guatemala',
            autore: 'Alessia Pezzato',
            descrizione: 'È il vulcano più attivo del Centro America, situato a soli 70 chilometri dalla capitale Città del Guatemala. Insieme allo stratovulcano non attivo Acatenango forma il complesso conosciuta come La Horqueta. Le sue eruzioni, registrate fin dal 1524, sono frequenti e vigorose, di piccole dimensioni a gas e eruzioni di cenere si verificano ogni 15-20 minuti e occasionalmente si producono colate piroclastiche e colate laviche.',
            anno: '2021',
            dimensioni: '90x200 cm',
            contatti: {
                instagram: 'https://www.insagram.com/alep22',
                email: 'alessia.pezzato97@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: 'https://www.linkedin.com/in/alessia-pezzato',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/WNhkdTR/90x200-Alessia-Pezzato-Volc-n-de-Fuego-Guatemala.jpg',
        },
        {
            id: '007',
            titolo: 'Mixed feelings',
            autore: 'Clara Pulpito, Alessio Iacobino',
            descrizione: 'Io e Alessio abbiamo scattato insieme con la sua analogica un po\'; di tempo fa. Ogni scatto è - letteralmente - di entrambi grazie all\'utilizzo dell\'esposizione multipla. La sovrapposizione delle immagini è l\'esatta trasposizione visiva di parte della nostra estate 2021, durante la quale un giorno spesso si confondeva con l\'altro, tanto da non riuscire più a collocare precisamente gli eventi nel tempo, che per un po\' ci è apparso distorto. Le foto sono così \'con-fuse\', nella stessa identica maniera in cui lo siamo stati noi che in quel breve periodo vivevamo quasi esclusivamente solo di notte, cercando di evadere un po\' dalla monotonia grazie all\' arrivo delle vacanze estive. Il titolo scelto indica le emozioni contrastanti che ci hanno pervasi, misti tra gioia, divertimento, rabbia e ansia per il futuro.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/petrolblues__',
                email: 'alessioiacobino02@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/PxDMczY/50x70-Clara-e-Alessio.jpg',
        },
        {
            id: '008',
            titolo: 'Mixed feelings',
            autore: 'Clara Pulpito, Alessio Iacobino',
            descrizione: 'Io e Alessio abbiamo scattato insieme con la sua analogica un po\'; di tempo fa. Ogni scatto è - letteralmente - di entrambi grazie all\'utilizzo dell\'esposizione multipla. La sovrapposizione delle immagini è l\'esatta trasposizione visiva di parte della nostra estate 2021, durante la quale un giorno spesso si confondeva con l\'altro, tanto da non riuscire più a collocare precisamente gli eventi nel tempo, che per un po\' ci è apparso distorto. Le foto sono così \'con-fuse\', nella stessa identica maniera in cui lo siamo stati noi che in quel breve periodo vivevamo quasi esclusivamente solo di notte, cercando di evadere un po\' dalla monotonia grazie all\' arrivo delle vacanze estive. Il titolo scelto indica le emozioni contrastanti che ci hanno pervasi, misti tra gioia, divertimento, rabbia e ansia per il futuro.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/clara.pulpito',
                email: 'Clah1269@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: 'https://www.behance.net/clarapulpito',
            },
            url: 'https://i.ibb.co/VHbk9V2/50x70-Clara-e-Alessio2.jpg',
        },
        {
            id: '009',
            titolo: 'Andata',
            autore: 'Pony Valentine',
            descrizione: 'Alicudi.\nDietro l’isola, alla ricerca di pesce.',
            anno: '2019',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'ponyvalentine25@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/0DSC0Xd/50x70-Elonora-Valentini-Alicudi2019-Andata.jpg',
        },
        {
            id: '010',
            titolo: 'Colore',
            autore: 'Pony Valentine',
            descrizione: 'Alicudi.\nA volte l’orizzonte sparisce, e ci si trova a fluttuare nei colori.',
            anno: '2019',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'ponyvalentine25@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/PhzRhtQ/50x70-Elonora-Valentini-Alicudi2019-Colore.jpg',
        },
        {
            id: '011',
            titolo: 'Flamingo (Palenque, Mexico)',
            autore: 'Alessia Pezzato',
            descrizione: 'All\'interno della meravigliosa giungla di Palenque, in Chiapas, mi sono imbattuta in Aluxes un\'associazione civile senza scopo di lucro e non governativa la quale missione è quella di salvare il bellissimo e variegato ecosistema del Chiapas dalla terribile distruzione degli ecosistemi iniziata dagli ultimi anni a questa parte.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/alep22',
                email: 'alessia.pezzato97@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: 'https://www.linkedin.com/in/alessia-pezzato',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/PNzP8FV/60x90-Alessia-Pezzato-Palenque-Mexico.jpg',
        },
        {
            id: '012',
            titolo: 'Basilicata, agosto 2021',
            autore: 'Nicole Di Stefano',
            descrizione: 'Poche persone, a volte nessuna; colori tenui e paesaggi che avvolgono. Una giostra molto lenta e molto colorata in mezzo alla natura. Magari messa lì per nessun motivo in particolare, ma senz\'altro in sintonia con l\'atmosfera del territorio che abita.',
            anno: '2021',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/_ceburashka_',
                email: 'nicole.97.distefano@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/TRYnKRM/60x90-Nicole-de-Stefano-Basilicata-agosto-2021.jpg',
        },
        {
            id: '013',
            titolo: 'Esperando',
            autore: 'Tommaso Cherubini',
            descrizione: 'Ho scattato questa foto a Tilcara, nel mezzo della Quebrada de Humahuaca è una valle Patrimonio dell\'UNESCO situata nella provincia di Jujuy, nell’Argentina nord-occidentale. Le persone raffigurate stanno attendendo il bus che le porterà sulle montagne. Alcune sono scese in città per fare la spesa, altre vendono panini fatti da loro ai viaggiatori. La provincia di Jujuy è formata da tanti minuscoli paesini tra le montagne, abitati da poche anime che vivono in modo semplice, estremamente allegro e solidale. A pochi chilometri da questi piccoli paesini si ergono meraviglie naturali come la Serranìa de Hornocal (o \'Montagna dai 14 colori\') e Las Salinas Grandes, un deserto bianco di sale che copre un\'area di 212km².',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tomminicherubaso',
                email: 'cherubini.tommaso@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/yn6Zg5z/60x90-Tommaso-Cherubini3.jpg',
        },
        {
            id: '014',
            titolo: 'Direzione',
            autore: 'Pony Valentine',
            descrizione: 'Alicudi.\nPoco dopo l’alba, alla ricerca di gamberetti.',
            anno: '2019',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'ponyvalentine25@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/FnR9D7d/50x70-Elonora-Valentini-Alicudi2019-Direzione.jpg',
        },
        {
            id: '015',
            titolo: 'Untitled',
            autore: 'Theresa Bader',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/theresabader',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/rcbZq46/90x200-Theresa-Bader.jpg',
        },
        {
            id: '016',
            titolo: 'Foggy Climber',
            autore: 'Jacopo Chiarva',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jakochiarva',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/St3CP9h/jacopo-chiarva.jpg',
        },
        {
            id: '017',
            titolo: 'La pace della neve',
            autore: 'Cristian Ghibaudo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/ghibo.5',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/rHsghKJ/La-pace-della-neve.jpg',
        },
        {
            id: '018',
            titolo: 'Sole d\'attesa',
            autore: 'Stefano Bertino',
            descrizione: 'Foto scattata su rullino formato 35mm con fotocamera compatta degli anni ‘90',
            anno: '2021',
            dimensioni: '40x60 cm',
            contatti: {
                instagram: 'https://www.instagram.com/ste.fun.o',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/YT8zzRs/stefano-bertino-bagno-dopo-mangiato.jpg',
        },
        {
            id: '019',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/RvNhLjH/50x70-Sam-Ficara1.jpg',
        },
        {
            id: '020',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/x77hVWk/50x70-Sam-Ficara3.jpg',
        },
        {
            id: '021',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/YcmTQWh/50x70-Sam-Ficara4.jpg',
        },
        {
            id: '022',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/gPB6MBV/50x70-Sam-Ficara.jpg',
        },
        {
            id: '023',
            titolo: 'EL BORRACHO',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/wz3Xp5r/50x70-Sam-Ficara-EL-BORRACHO-BN.jpg',
        },
        {
            id: '024',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/mtzK1Yp/50x70-Sam-Ficara.jpg',
        },
        {
            id: '025',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/jZtRh0N/50x70-Sam-Ficara-jpg.jpg',
        },
        {
            id: '026',
            titolo: 'Inertia, Lanzarote',
            autore: 'Silvia Tiralongo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/silvya112',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/9qz3qY9/50x70-Silvia-Tiralongo.jpg',
        },
        {
            id: '027',
            titolo: 'Rick Natura',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'Non è l’uomo che deve battersi contro una natura ostile, ma è la natura indifesa che da generazioni è vittima dell’umanità.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/RHdHy08/NATURA.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Viaggi | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/viaggi">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/WNhkdTR/90x200-Alessia-Pezzato-Volc-n-de-Fuego-Guatemala.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Viaggi</h1>
                                <p>Aula studio</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/viaggi">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/WNhkdTR/90x200-Alessia-Pezzato-Volc-n-de-Fuego-Guatemala.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Viaggi</h1>
                                    <p>Aula studio</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Protesta() {

    const opere = [
        {
            id: '001',
            titolo: '15 gennaio 2022, Torino',
            autore: 'Andrea Terlizzi',
            descrizione: '',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: '',
                email: 'terlizzi.andrea26@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/fCvXS3h/60x90-ANDREA-TERLIZZI.jpg',
        },
        {
            id: '002',
            titolo: 'GLI INVISIBILI',
            autore: 'Caterina Mascolo',
            descrizione: 'Fermo immobile, fissava un punto perso a Porta Palazzo. Speravo guardasse dritto nel mio obiettivo con il suo sguardo pieno di speranza e vuoto di vita. Aveva le mani sporche e gli occhi azzurri. L\'invisibile non ha un nome ma io lo vedo, magari un giorno lo avrà.',
            anno: '2019',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: '',
                email: '',
                facebook: '',
                twitter: '',
                website: 'caterinamascolo@yahoo.it',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/D4QGNj9/60x90-Caterina-Mascolo.jpg',
        },
        {
            id: '003',
            titolo: 'Ageless Revolution',
            autore: 'Tommaso Cherubini',
            descrizione: 'Ho scattato questa foto a Santiago de Chile nel Dicembre 2019, durante le proteste che hanno portato il Chile ad un Referendum per una nuova Costituzione ed all\'elezione di un nuovo Presidente. Questa anziana signora regge un cartello raffigurante un occhio, che simboleggia la necessità di aprire gli occhi e smettere di ignorare la mancanza di diritti in Cile. Dal 2019 al 2021 si sono svolte proteste civili in tutto il Cile, causate dall\'aumento della corruzione, del costo della vita, delle privatizzazioni e delle disuguaglianze. Milioni di persone di ogni età, ceto sociale e provenienza sono scese in strada per far sentire la loro voce. Gli scontri con la polizia hanno provocato 36 morti, oltre 11.000 feriti e più di 25.000 persone arrestate.',
            anno: '2019',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/tomminicherubaso',
                email: 'cherubini.tommaso@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/7Jwbx5W/60x90-Tommaso-Cherubini1.jpg',
        },
        {
            id: '004',
            titolo: 'CATTIVE ABITUDINI',
            autore: 'Christian Convertini',
            descrizione: 'Illusi dall’apparenza, non dimostriamo altro che l’esatto contrario di ciò che siamo.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/christian_convertini',
                email: 'chrisconvarts@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/D7YT6MX/CATTIVE-ABITUDINI.jpg',
        },
        {
            id: '005',
            titolo: 'Smokin’ revolt',
            autore: 'Francesco Milanese',
            descrizione: 'Immerso nel fumo rosso di un fumogeno, uno studente in protesta prova a non soffocarsi da solo, immergendo la faccia nella manica di una giacca rubata al proprio padre, probabilmente.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/framilanese',
                email: 'fra.player.02@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/xmkrJ4G/DSCF5676.jpg',
        },
        {
            id: '006',
            titolo: 'SCAPPA',
            autore: 'Christian Convertini',
            descrizione: 'Pare tutta una sceneggiata; maschere fatte di ipocrisia, non a caso in antichità "l’ipocrita" era l’attore sulla scena teatrale.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/christian_convertini',
                email: 'chrisconvarts@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/6H05Ldd/SCAPPA.jpg',
        },
        {
            id: '007',
            titolo: '15 gennaio 2022, Torino',
            autore: 'Andrea Terlizzi',
            descrizione: '',
            anno: '2022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'terlizzi.andrea26@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Fq1n3pj/50x70-ANDREA-TERLIZZI.jpg',
        },
        {
            id: '008',
            titolo: 'Risolutore della tragedia',
            autore: 'Ylenia Vitolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/yle_foto',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/x17dw1Y/60x90-Ylenia-Vitolo.jpg',
        },
        {
            id: '009',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/qyVkCyZ/50x70-Sam-Ficara.jpg',
        },
        {
            id: '010',
            titolo: 'Rick Proteste',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'Chi è in guerra con gli altri non è in pace con se stesso.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/kxdDT4k/MANIFESTAZIONE.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Protesta | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/protesta">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/xmkrJ4G/DSCF5676.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Protesta</h1>
                                <p>TensoBar</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/protesta">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/xmkrJ4G/DSCF5676.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Protesta</h1>
                                    <p>TensoBar</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Underground() {

    const opere = [
        {
            id: '001',
            titolo: 'Untitled',
            autore: 'Maria Sole Castoldi',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/itsonlysole',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/pyC1mxK/maria-sole-castoldi.jpg',
        },
        {
            id: '002',
            titolo: 'Untitled 1',
            autore: 'Vincenzo Caforio',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/space.pvnk',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/5WmNY3t/vincenzo-caforio.jpg',
        },
        {
            id: '003',
            titolo: 'Untitled 2',
            autore: 'Vincenzo Caforio',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/space.pvnk',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/9WWqgbT/vincenzo-caforio2.jpg',
        },
        {
            id: '004',
            titolo: 'Matteo, Berlin 2018',
            autore: 'Flavie Guerrand Matteo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/flavieguerrand',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/52ZGgxN/50x70-Flavie-Guerrand-Matteo.jpg',
        },
        {
            id: '005',
            titolo: 'Untitled',
            autore: 'Robert Matejcek',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: 'robertmatejcek@hotmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Lk5bZS8/50x70-robert-matejcek.jpg',
        },
        {
            id: '006',
            titolo: 'Untitled',
            autore: 'Robert Matejcek',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: 'robertmatejcek@hotmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/xGqVr9H/50x70-robert-matejcek2.jpg',
        },
        {
            id: '007',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/M9Ks91G/50x70-Sam-Ficara2.jpg',
        },
        {
            id: '008',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/bdF1hDx/50x70-Sam-Ficara3.jpg',
        },
        {
            id: '009',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/sR6CBNC/50x70-Sam-Ficara4.jpg',
        },
        {
            id: '010',
            titolo: 'Untitled',
            autore: 'Sam Ficara',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/samficara/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/QJNtD6M/50x70-Sam-Ficara1.jpg',
        },
        {
            id: '011',
            titolo: 'Untitled 3',
            autore: 'Vincenzo Caforio',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/space.pvnk',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/yk1zXgB/60x90vincenzo-caforio.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Underground | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/underground">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/sR6CBNC/50x70-Sam-Ficara4.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Underground</h1>
                                <p>Via delle Spezie</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/underground">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/sR6CBNC/50x70-Sam-Ficara4.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Underground</h1>
                                    <p>Via delle Spezie</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Urban() {

    const opere = [
        {
            id: '001',
            titolo: 'Yin ‘n Yang',
            autore: 'Francesco Milanese',
            descrizione: 'A volte hai voglia di fare quello che ami, magari prendere il tuo skate e divertirti nella metro Viennese. A volte no, a volte, basta mettersi al sole a rilassarsi, a pensare.',
            anno: '2022',
            dimensioni: '90x200 cm',
            contatti: {
                instagram: 'https://www.instagram.com/framilanese',
                email: 'fra.player.02@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/X5N3bKt/DSCF8377.jpg',
        },
        {
            id: '002',
            titolo: 'Urban Abstraction',
            autore: 'Edgardo Maxia',
            descrizione: '',
            anno: '2017',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/edgardomaxia',
                email: 'edgardomaxia@gmail.com',
                facebook: '',
                twitter: '',
                website: 'https://www.edgardomaxia.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/rmjswZL/50x70-Edgardo-Maxia-IMG-4432.jpg',
        },
        {
            id: '003',
            titolo: 'Con me',
            autore: 'Ylenia Vitolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/yle_foto',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/QrMW3Lj/60x90-Ylenia-Vitolo-Con-me.jpg',
        },
        {
            id: '004',
            titolo: 'Little girl blue',
            autore: 'Giusy Catania',
            descrizione: 'Mi chiedo spesso cosa stiano pensando o vivendo le persone che mi stanno intorno, sconosciuti, si certo, ma con storie da raccontare. Allora un giorno ho pensato di inventare un gioco: immagino racconti sulla gente che mi sta accanto nelle quali immedesimarmi. Little girl blue parla di quest’uomo in viaggio dalla Germania per visitare Roma, una Roma particolarmente rovente quell’estate. L’unico spazio fresco era la metropolitana, dove incontrò lo sguardo di una piccola ragazza con i capelli blu.',
            anno: '2019',
            dimensioni: '70x50 cm',
            contatti: {
                instagram: 'https://www.instagram.com/gius_ycatania/',
                email: 'giusycatania20@gmail.com',
                facebook: '',
                twitter: '',
                website: 'https://500px.com/p/giusycatania20?view=photos',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/xF55MZG/1.jpg',
        },
        {
            id: '005',
            titolo: 'Untitled',
            autore: 'Gaia Asquino',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/acciderbolyna',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/RpkrP4R/50x70-Gaia-Asquino-2.jpg',
        },
        {
            id: '006',
            titolo: 'Untitled',
            autore: 'Gaia Asquino',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/acciderbolyna',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/gZvn1yZ/50x70-Gaia-Asquino1.jpg',
        },
        {
            id: '007',
            titolo: 'Benvenuto nella tua desertica, nuova realtà',
            autore: 'Matteo Zambon',
            descrizione: 'Un comune deposito per automobili rende un’immagine nitida di come tecnologia e progresso possano evolvere distopicamente in deumanizzazione e assenza di vita; una visione di un mondo in cui le macchine create dall’uomo e per l’uomo portano l’essere umano a essere privo dei tratti che lo rendono tale. L’opera è un tributo all’iconografia della fantascienza distopica, con particolare riferimento ad artisti come Giger e al capolavoro cinematografico “Matrix”.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'matteo.zambon98@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/n0fgCG9/50x70-Matteo-Zambon.jpg',
        },
        {
            id: '008',
            titolo: 'Oblio',
            autore: 'Ylenia Vitolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/yle_foto',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/r0D3FtP/60x90-Ylenia-Vitolo.jpg',
        },
        {
            id: '009',
            titolo: 'Urban e underground',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'C’è chi guarda le cose come sono e poi si chiede “perchè”. Io penso a come potrebbero essere e poi mi chiedo “perché no?”.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/0GPW4W9/UNDERGROUND.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Urban | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/urban">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/QrMW3Lj/60x90-Ylenia-Vitolo-Con-me.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Urban</h1>
                                <p>Via delle Spezie</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/urban">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/QrMW3Lj/60x90-Ylenia-Vitolo-Con-me.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Urban</h1>
                                    <p>Via delle Spezie</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Grafiche() {

    const opere = [
        {
            id: '001',
            titolo: 'iMilk',
            autore: 'Enrico Dedin',
            descrizione: 'L’abuso di tecnologia digitale da parte di bambini in età prescolare e preadolescenziale può avere un forte impatto neurologico, psicologico e comportamentale. La prima fase di vita è quella in cui il cervello è più ricettivo e in grado di svilupparsi in base agli stimoli esterni. Proprio per questo l’uso sconsiderato dei dispositivi digitali fa sì che il cervello non venga stimolato nel modo corretto, generando così ritardi cognitivi, deficit d’attenzione e diminuendo le capacità di apprendimento e di autoregolarsi. Giocando davanti a uno schermo i bambini vengono privati dell’esperienza naturale della terza dimensione e la loro fantasia è limitata da giochi predefiniti e meccanici.',
            anno: '2019-1022',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'enrico.dedin@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/GHn3z8g/Enrico-Dedin-i-Milk-50x70-1.jpg',
        },
        {
            id: '002',
            titolo: 'Trip',
            autore: 'Elena Da Sois',
            descrizione: 'Di fronte all’impossibilità di dare un senso alla vita e alla morte l’essere umano tende a sentirsi sopraffatto dallo sconforto. Immaginando di vedere il mondo da lontano però diventa divertente vedere quanto ogni persona si affanni per riempire il tempo che ha a disposizione e all’improvviso ogni problema sembra più leggero, tutto si riduce ad un gioco. Laddove qualcosa non ha uno scopo ma basta la sua esistenza a renderla degna di ammirazione, quella è una forma d’arte.',
            anno: '2022',
            dimensioni: '420x297 cm',
            contatti: {
                instagram: 'https://www.instagram.com/elena.dasois.photography/',
                email: 'elena.dasois@ied.edu',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: 'https://www.behance.net/ellendaizu22d2',
            },
            url: 'https://i.ibb.co/0GvSB3Z/Elena-Da-Sois3.jpg',
        },
        {
            id: '003',
            titolo: 'Moon',
            autore: 'Paolo Stralla',
            descrizione: 'Quest’opera è temporalmente successiva a “Release” e rappresenta lo stato di pace interiore conseguente allo sfogo liberatorio avvenuto nell’opera precedente.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/palofuz',
                email: 'paolo.stralla@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/CQ9qTdP/50x70-Paolo-Stralla-4.jpg',
        },
        {
            id: '004',
            titolo: 'Physis',
            autore: 'Paolo Stralla',
            descrizione: 'Reinterpretazione di Physis, Forza della natura e divinità ordinatrice di tutte le cose. Qui viene rappresentata intenta a popolare la Terra di flora e fauna, milioni di anni fa. Quest’opera si contrappone a “Rebirth”, in cui in un futuro remoto Physis salva l’ultima forma di vita presente sul pianeta.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/palofuz',
                email: 'paolo.stralla@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/wCMf3xg/50x70-Paolo-Stralla1.jpg',
        },
        {
            id: '005',
            titolo: 'Winged Hebe',
            autore: 'Paolo Stralla',
            descrizione: 'Reinterpretazione estetica in chiave moderna della Dea Ebe.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/palofuz',
                email: 'paolo.stralla@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/tcYw6Tz/50x70-Paolo-Stralla2.jpg',
        },
        {
            id: '006',
            titolo: 'Release',
            autore: 'Paolo Stralla',
            descrizione: 'Rappresentazione allegorica di uno sfogo liberatorio conseguente a un accumulo eccessivo di stress e ansia. Quest’opera venne realizzata in un periodo particolarmente pesante, che una volta concluso ha portato alla realizzazione di “Moon”.',
            anno: '2020',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: 'https://www.instagram.com/palofuz',
                email: 'paolo.stralla@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/cJd9W8x/50x70-Paolo-Stralla3.jpg',
        },
        {
            id: '007',
            titolo: 'Unblockable',
            autore: 'Atra Bilis',
            descrizione: 'L’opera “Unblockable” si ispira alla libertà, vuole trasmettere quello che tutti noi vorremo fare, uscire fuori dai nostri blocchi, senza freni, proprio come la naturale nessuno può fermare nonostante tutto.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/atra_bilis/',
                email: 'atra_bilis@hotmail.com',
                facebook: 'https://m.facebook.com/AtraBilis616/',
                twitter: 'https://mobile.twitter.com/AtraBilis3',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/BtKSqby/60x90-Atra-Bilis.jpg',
        },
        {
            id: '008',
            titolo: 'Reborn',
            autore: 'Atra Bilis',
            descrizione: 'L’opera “Reborn” si ispira ai processi naturali rapportati a quelli umani, la rinascita, quella che la natura compie ogni giorno e che dovremmo prendere come esempio.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/atra_bilis/',
                email: 'atra_bilis@hotmail.com',
                facebook: 'https://m.facebook.com/AtraBilis616/',
                twitter: 'https://mobile.twitter.com/AtraBilis3',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/85H9G4y/60x90-Atra-Bilis2.jpg',
        },
        {
            id: '009',
            titolo: 'Desire',
            autore: 'Atra Bilis',
            descrizione: 'L’opera “Desire” si ispira a quello che alla natura ci offre e ai suoi scenari inimmaginabili per noi, indica il desiderio di un mondo che non esiste che solo la natura può regalarci',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/atra_bilis/',
                email: 'atra_bilis@hotmail.com',
                facebook: 'https://m.facebook.com/AtraBilis616/',
                twitter: 'https://mobile.twitter.com/AtraBilis3',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/VxfPYYQ/60x90-Atra-Bilis3.jpg',
        },
        {
            id: '010',
            titolo: 'Hate I',
            autore: 'Bianca Mancin',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/interviewblanche',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/cYGkwvC/BIANCA-MANCIN.jpg',
        },
        {
            id: '011',
            titolo: 'Black Alphabet',
            autore: 'Pietro Cervino',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/shekoone',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/fCLGQCF/pietro-cervino.jpg',
        },
        {
            id: '012',
            titolo: 'Rage',
            autore: 'Simone Calabrese',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/calabresesimone',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/4KkR3bT/simone-calabrese.jpg',
        },
        {
            id: '013',
            titolo: 'Angel',
            autore: 'Tommaso Sergi',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tommaso_sergi',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/hWXCJ2x/tommaso-sergi-1.jpg',
        },
        {
            id: '014',
            titolo: 'To Brighter Days',
            autore: 'Tommaso Sergi',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tommaso_sergi',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/tzCkntz/tommaso-sergi.jpg',
        },
        {
            id: '015',
            titolo: 'The Price Of Freedom',
            autore: 'Zeno Mora',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/zenomora.studio',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/JsKQwfq/zeno-mora-The-Price-Of-Freedom.jpg',
        },
        {
            id: '016',
            titolo: 'Hate II',
            autore: 'Bianca Mancin',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/interviewblanche',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/34MBnL5/BIANCA-MANCIN-1.jpg',
        },
        {
            id: '017',
            titolo: 'Scampi',
            autore: 'Elena Da Sois',
            descrizione: 'Non conoscere una persona equivale identificarla con ciò che fa nel contesto in cui ne siamo venuti a contatto. Quell’uomo incontrato in pescheria è il pescivendolo. Non ha importanza sapere il suo nome ma basta sapere che a lui ci fornisce il pesce, come se non avesse un volto e una voce. Questa riflessione non mira ad essere una critica alla società, piuttosto si trasforma in un pretesto per immaginare quanto sarebbe buffo se gli occhi vedessero cosa vede la nostra mente.',
            anno: '2022',
            dimensioni: '420x297 cm',
            contatti: {
                instagram: 'https://www.instagram.com/elena.dasois.photography/',
                email: 'elena.dasois@ied.edu',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: 'https://www.behance.net/ellendaizu22d2?tracking_source=search_projects',
            },
            url: 'https://i.ibb.co/SfGZ7B1/Elena-Da-Sois2.jpg',
        },
        {
            id: '018',
            titolo: 'Don’t for get about the magic don’t for get about the soul',
            autore: 'Tessa de Swart',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tessadeswart',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/S769CV7/50x70-Tessa-de-Swart-Don-t-forget-about-the-magic-Don-t-forget-about-the-soul-71-30-x50-cm-photopape.jpg',
        },
        {
            id: '019',
            titolo: 'To bring you my love',
            autore: 'Tessa de Swart',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/tessadeswart',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/y0DT5CB/50x70-Tessa-de-Swart-to-bring-you-my-love-71-30-x50-cm-photopaper-on-dibond-2021.jpg',
        },
        {
            id: '020',
            titolo: 'Identity',
            autore: 'Alice Musso',
            descrizione: 'Questo progetto è nato da una delle frasi che probabilmente tutti si sentono dire quando incontrano qualche amico di famiglia o persone che non si vedono da tanto tempo, ovvero “sei tutt* tua madre/tuo padre”. L’idea iniziale era quella di confrontare noi stessi con quel genitore al quale meno persone sembrano associarci, ma la scelta finale è ricaduta su qualcuno di facilmente riconoscibile, come dei personaggi famosi. Alcuni di questi sono stati scelti perchè non hanno buoni rapporti con il proprio famigliare. Come si vede dai diversi poster i tratti fisionomici combaciano perfettamente, questo vuole ricordare che anche non si è più in buoni rapporti si è sempre legati in qualche modo. Stampa su carta',
            anno: '2019/2020',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: 'ali.alice1997@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/LvcxC3V/musso-01.jpg',
        },
        {
            id: '021',
            titolo: 'Identity',
            autore: 'Alice Musso',
            descrizione: 'Questo progetto è nato da una delle frasi che probabilmente tutti si sentono dire quando incontrano qualche amico di famiglia o persone che non si vedono da tanto tempo, ovvero “sei tutt* tua madre/tuo padre”. L’idea iniziale era quella di confrontare noi stessi con quel genitore al quale meno persone sembrano associarci, ma la scelta finale è ricaduta su qualcuno di facilmente riconoscibile, come dei personaggi famosi. Alcuni di questi sono stati scelti perchè non hanno buoni rapporti con il proprio famigliare. Come si vede dai diversi poster i tratti fisionomici combaciano perfettamente, questo vuole ricordare che anche non si è più in buoni rapporti si è sempre legati in qualche modo. Stampa su carta',
            anno: '2019/2020',
            dimensioni: '',
            contatti: {
                instagram: '',
                email: 'ali.alice1997@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/gPJ62Wd/musso-02.jpg',
        },
        {
            id: '022',
            titolo: 'Rick Grafiche',
            autore: 'Elisabetta Zanirato, Marta Caruso',
            descrizione: 'La tenebra non può scacciare la tenebra: solo la luce può farlo. L’odio non può scacciare l’odio: solo l’amore può farlo',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/martacaruss',
                email: 'caruso.marta207@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/8c73RYZ/GRAFICA.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Grafiche Digitali | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/grafiche">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/85H9G4y/60x90-Atra-Bilis2.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Grafiche Digitali</h1>
                                <p>Via delle Spezie</p>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/grafiche">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/85H9G4y/60x90-Atra-Bilis2.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Grafiche Digitali</h1>
                                    <p>Via delle Spezie</p>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export function Extra() {

    const opere = [
        {
            id: '001',
            titolo: 'Untitled',
            autore: 'Maria Sole Castoldi',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/itsonlysole',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/zHnjssn/Maria-Sole-Castoldi.jpg',
        },
        {
            id: '002',
            titolo: 'Apparente felicità (1/3)',
            autore: 'Sara Celia',
            descrizione: 'Sono 3 foto che vogliono rappresentare delle sensazioni di apparente felicità attraverso i colori e la tecnica.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: '',
                email: 'sara.celia0987@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/VqDV5QM/sara-celia-1.jpg',
        },
        {
            id: '003',
            titolo: 'Apparente felicità (2/3)',
            autore: 'Sara Celia',
            descrizione: 'Sono 3 foto che vogliono rappresentare delle sensazioni di apparente felicità attraverso i colori e la tecnica.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: '',
                email: 'sara.celia0987@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: 'https://i.ibb.co/02tBqzv/sara-celia-2.jpg',
            },
            url: 'https://i.ibb.co/02tBqzv/sara-celia-2.jpg',
        },
        {
            id: '004',
            titolo: 'Apparente felicità (3/3)',
            autore: 'Sara Celia',
            descrizione: 'Sono 3 foto che vogliono rappresentare delle sensazioni di apparente felicità attraverso i colori e la tecnica.',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: '',
                email: 'sara.celia0987@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/MDFccy5/sara-celia-3.jpg',
        },
        {
            id: '005',
            titolo: 'Untitled',
            autore: 'Stefano Brunatto',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/ste_brunatto',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/hZyz8j7/stefano-brunatto.jpg',
        },
        {
            id: '006',
            titolo: 'Swipe to unlock',
            autore: 'Pietro Antonio Luigi',
            descrizione: '',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/vistodapietro/',
                email: 'pietro.luigi2002@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/Lg74VhJ/3.jpg',
        },
        {
            id: '007',
            titolo: 'Poker Face',
            autore: 'Eliseo Martelli',
            descrizione: 'Lo spazio negativo presente in questa foto ha il compito di portare l’attenzione sulla gallina, sul suo sguardo e sulla sua smorfia che osserva l’uomo con un fare sbarazzino.',
            anno: '2021',
            dimensioni: '50x70 cm',
            contatti: {
                instagram: '',
                email: 'me@eliseomartelli.it',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/pZpRDGj/50x70-Eliseo-Martelli.jpg',
        },
        {
            id: '008',
            titolo: 'Refinery utopia',
            autore: 'Edgardo Maxia',
            descrizione: 'Questa è la raffineria della Saras, luogo magico, pieno di guglie e fumi, la sublime immagine dell’Uomo che fin dalla pietra arriva al petrolio. Lavoro, occupazione, e quei fuochi galleggianti che si vedono da lontano. Foto numero 74 tratta dal mio progetto 365.',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/uovardo',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/fvS1MM8/50x70-Edgardo-Maxia3.jpg',
        },
        {
            id: '009',
            titolo: 'U.S. (Unwholesome Shelter)',
            autore: 'Jiang Feng',
            descrizione: '',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jiangfeng_mine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.jiangfeng-mine.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/QkNTQy6/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-01.jpg',
        },
        {
            id: '010',
            titolo: 'U.S. (Unwholesome Shelter)',
            autore: 'Jiang Feng',
            descrizione: '',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jiangfeng_mine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.jiangfeng-mine.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/34qNRv0/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-02.jpg',
        },
        {
            id: '011',
            titolo: 'U.S. (Unwholesome Shelter)',
            autore: 'Jiang Feng',
            descrizione: '',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jiangfeng_mine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.jiangfeng-mine.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/w6bTnz7/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-07.jpg',
        },
        {
            id: '012',
            titolo: 'U.S. (Unwholesome Shelter)',
            autore: 'Jiang Feng',
            descrizione: '',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jiangfeng_mine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.jiangfeng-mine.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/ggHdR1q/50x70-U-S-Unwholesome-Shelter-01.jpg',
        },
        {
            id: '013',
            titolo: 'U.S. (Unwholesome Shelter)',
            autore: 'Jiang Feng',
            descrizione: '',
            anno: '2019',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/jiangfeng_mine/',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://www.jiangfeng-mine.com/',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/ZVRBwBP/50x70-U-S-Unwholesome-Shelter-03.jpg',
        },
        {
            id: '014',
            titolo: 'I\'m Fine.',
            autore: 'Rei Etra',
            descrizione: 'Maschere. Indossiamo maschere tutti i giorni. Per conformarci alla società. Per non sembrare diversi. Ci nascondiamo dietro a frasi o parole convenzionali, al fine di rientrare negli "standard previsti". Ci vestiamo ogni giorno delle nostre versioni idealizzate, per non sentirci deboli e non mostrarci fragili. Ma facendo così, cosa siamo in realtà? Semplici ipocriti? O vittime della nostra paura nel mostrarci fragili?',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/rei.etra',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://reietra.myportfolio.com',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/yyS4pjd/60x90-Rei-Etra.jpg',
        },
        {
            id: '015',
            titolo: 'Sentire è come il cielo, si vede e non c\'è nulla da vedere.',
            autore: 'Rei Etra',
            descrizione: '',
            anno: '2022',
            dimensioni: '60x90 cm',
            contatti: {
                instagram: 'https://www.instagram.com/rei.etra',
                email: '',
                facebook: '',
                twitter: '',
                website: 'https://reietra.myportfolio.com',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/PFLgkb6/60x90-Rei-Etra2.jpg',
        },
        {
            id: '016',
            titolo: 'Snowbear',
            autore: 'Maria Carolina Uribe',
            descrizione: '',
            anno: '2022',
            dimensioni: '90x200 cm',
            contatti: {
                instagram: 'https://www.instagram.com/mc_santoro',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/T2C8mbK/90x200-Maria-Carolina-Uribe-Snowbearjpg.jpg',
        },
        {
            id: '017',
            titolo: 'Untitled',
            autore: 'Andrea Simeone',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/andrea___sim/',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/6npJJfd/andrea-simeone.jpg',
        },
        {
            id: '018',
            titolo: 'Cliff Jumping',
            autore: 'Didier Barontini',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/didierbarontini',
                email: '',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/5sd0BBD/didier-barontini.jpg',
        },
        {
            id: '019',
            titolo: 'Aletheia 3',
            autore: 'Fabiana Fogagnolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/fabiana.fogagnolo',
                email: 'fabiana.fogagnolo@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/CQtKswm/Fabiana-Fogagnolo-3.jpg',
        },
        {
            id: '020',
            titolo: 'Aletheia 4',
            autore: 'Fabiana Fogagnolo',
            descrizione: '',
            anno: '',
            dimensioni: '',
            contatti: {
                instagram: 'https://www.instagram.com/fabiana.fogagnolo',
                email: 'fabiana.fogagnolo@gmail.com',
                facebook: '',
                twitter: '',
                website: '',
                linkedin: '',
                tiktok: '',
                behance: '',
            },
            url: 'https://i.ibb.co/jhqSbjC/Fabiana-Fogagnolo-4.jpg',
        },
    ]

    let flag = true

    return (
        <div>
            <MetaDecorator
                title="Extra | iosonotheplace vol.2 | The Place APS"
                description="Mostra fotografica online e fisica presso l'Associazione Culturale Comala a Torino. 140 opere e 60 artisti da tutto il mondo. Guarda la mostra anche online."
                keywords="comala, associazione comala, comala torino, mappa, opere, mostra, mostra fotografica, fotografie, artisti, viaggi, categorie mostra, iosonotheplace, io sono the place, io sono theplace, spleen, corpo, donna, protesta, underground, urban, grafiche digitali, grafiche, extra"
            />
            <Navbar />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/iosonotheplace-vol-2/extra">
                        <Container
                            fluid
                            className="pt-5 d-flex justify-content-center align-items-center"
                            style={{height:'60vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/34qNRv0/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-02.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                        >
                            <div className="text-center">
                                <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Extra</h1>
                            </div>
                        </Container>
                        <Container>
                            {
                                opere.map(o => {
                                    if(flag===false) flag=true
                                    else if(flag===true) flag=false
                                    return <div className="py-5 my-5" key={o.id}><CardOpera data={o} flag={flag}/></div>
                                })
                            }
                        </Container>
                    </Route>
                    <Route path="/iosonotheplace-vol-2/extra">
                        <div style={{paddingTop:'8em'}}>
                            <Container className="py-5">
                                <Opera data={opere} />
                            </Container>
                            <Container className="pb-5 text-center">
                                <a href="https://www.theplace.family/iosonotheplace-vol-2/">
                                    <Button
                                        variant="light"
                                        className="rounded10"
                                    >
                                        &larr; Torna alla mostra
                                    </Button>
                                </a>
                            </Container>
                            <Container
                                fluid
                                className="pt-5 d-flex justify-content-center align-items-center"
                                style={{height:'40vh', backgroundColor:'#000', backgroundImage:`url('https://i.ibb.co/34qNRv0/50x70-Jiang-Feng-U-S-Unwholesome-Shelter-02.jpg')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center',}}
                            >
                                <div className="text-center">
                                    <h1 className="fw-bold mb-2" style={{fontSize:'4em'}}>Extra</h1>
                                </div>
                            </Container>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}
