import {Button, Col, Container, Row} from "react-bootstrap";
import logoWhite from "../logo/logo_white.png";
import {Link} from "react-router-dom";


export function Navbar() {

    return (
        <div className="py-4" style={{backgroundColor:'#101010', position:'fixed', top:0, width:'100%', zIndex:999999, maxWidth:'100vw'}}>
            <Container className="d-block d-md-none d-flex justify-content-between align-items-center px-4">
                <div>
                    <Link
                        to="/"
                    >
                        <img
                            src={logoWhite}
                            height={60}
                            alt="logoThePlace"
                            className="px-3"
                        />
                    </Link>
                </div>
                <div>
                    <Row className="text-uppercase">
                        <Col className="d-flex justify-content-center align-items-center">
                            <Link
                                to="/"
                            >
                                Home
                            </Link>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <Link
                                to="/progetti"
                            >
                                PROGETTI
                            </Link>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <Link
                                to="/faq"
                            >
                                FAQ
                            </Link>
                        </Col>
                        <div className="d-flex justify-content-evenly align-items-center mt-2">
                            <a
                                href="https://my.theplace.family/"
                                className="w-100 px-1"
                            >
                                <Button
                                    variant="danger"
                                    className="rounded10 fw-bold w-100"
                                    size="sm"
                                >
                                    MEMBERS
                                </Button>
                            </a>

                            {
                                /**

                                 <a
                                 href="https://www.theplace.family/open-call-castello-del-valentino"
                                 className="w-100 px-1"
                                 >
                                 <Button
                                 variant="warning"
                                 className="rounded10 fw-bold w-100"
                                 size="sm"
                                 >
                                 OPEN CALL
                                 </Button>
                                 </a>

                                 */
                            }
                        </div>
                    </Row>
                    {
                        /**

                         <div className="mt-2">
                         <Link
                         to="/members"
                         style={{width:'75%'}}
                         >
                         <Button
                         variant="danger"
                         className="rounded10 fw-bold w-100"
                         size="sm"
                         >
                         MEMBERS
                         </Button>
                         </Link>
                         </div>

                         */
                    }
                </div>
            </Container>
            <div className="d-none d-md-block">
                <Container className="d-flex justify-content-between align-items-center">
                    <Row>
                        <Col md="auto">
                            <Link
                                to="/"
                            >
                                <img
                                    src={logoWhite}
                                    height={60}
                                    alt="logoThePlace"
                                />
                            </Link>
                        </Col>
                        <Col className="d-none d-md-block">
                            <h3 className="fw-bold">The Place</h3>
                            <p className="small text-uppercase opacity-75 fw-light">Associazione culturale di promozione sociale</p>
                        </Col>
                    </Row>
                    <div className="px-5">
                        <div className="text-uppercase d-flex justify-content-evenly align-items-center">
                            <div className="mx-2">
                                <Link
                                    to="/"
                                >
                                    Home
                                </Link>
                            </div>
                            <div className="mx-2">
                                <Link
                                    to="/progetti"
                                >
                                    PROGETTI
                                </Link>
                            </div>
                            <div className="mx-2">
                                <Link
                                    to="/faq"
                                >
                                    FAQ
                                </Link>
                            </div>
                            <div className="mt-2 mt-md-0 mx-2">
                                <a
                                    href="https://my.theplace.family/"
                                >
                                    <Button
                                        variant="danger"
                                        className="rounded10 fw-bold"
                                        size="sm"
                                    >
                                        MEMBERS
                                    </Button>
                                </a>
                            </div>
                            {
                                /**

                                 <div className="mt-2 mt-md-0 mx-1">
                                 <a
                                 href="https://www.theplace.family/open-call-castello-del-valentino"
                                 >
                                 <Button
                                 variant="warning"
                                 className="rounded10 fw-bold"
                                 size="sm"
                                 >
                                 OPEN CALL
                                 </Button>
                                 </a>
                                 </div>

                                 */
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
