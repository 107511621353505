import {FaCookieBite, FaTimes} from "react-icons/fa";
import {useEffect, useState} from "react";
import {Badge, Col, Row} from "react-bootstrap";


export function CookiesBanner() {

    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setTimeout(() => setVisible(true), 1000)
    }, [])


    return (
        <div
            className={visible ? 'cookiesBannerVisible' : 'cookiesBannerNotVisible'}
        >
            <div className="cookiesBanner py-3 px-4">
                <FaTimes
                    size="0.75em"
                    className="iconSocialOverlay"
                    color="#000"
                    style={{
                        position: "absolute",
                        top: 7.5,
                        right: 7.5
                    }}
                    onClick={() => setVisible(false)}
                />
                <Row className="mt-1">
                    <Col xs="auto" className="d-flex justify-content-center align-items-center">
                        <FaCookieBite
                            size="1.75em"
                        />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center">
                        <div>
                            <p className="small text-center">Usiamo alcuni cookie per rendere la tua esperienza migliore.</p>
                            <p className="small text-center">Rispettiamo la tua privacy. &nbsp;<br className="d-md-none"/>&nbsp; <Badge bg="secondary" className="iconHover" onClick={() => window.location.href='https://www.theplace.family/privacy'}>Leggi di più</Badge>&nbsp;&nbsp;<Badge bg="danger" className="iconHover" onClick={() => {
                                localStorage.setItem('cookies-consent-theplace', 'cookiesPolicyAccepted')
                                setVisible(false)
                            }}>Accetto</Badge></p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
