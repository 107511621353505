import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import logoWhite from "../logo/logo_white.png"
import {Navbar} from "../OnePageHome/Navbar";
import {MetaDecorator} from "./MetaDecorator";


export function Page404() {
    return (
        <Container className="d-flex justify-content-center align-items-center" style={{minHeight:'100vh'}}>
            <MetaDecorator
                title="404 | The Place APS"
            />
            <Navbar />
            <div className="text-center">
                <img
                    src={logoWhite}
                    height={80}
                    className="mb-5"
                    alt="logoThePlace"
                />
                <h1 className="big fw-bold">404</h1>
                <p>Pagina non trovata.</p>
                <div className="mt-5">
                    <Link
                        to="/"
                    >
                        &larr; Home
                    </Link>
                </div>
            </div>
        </Container>
    )
}
