import {Navbar} from "./OnePageHome/Navbar";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import logoWhite from "./logo/logo_white.png";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";
import {MetaDecorator} from "./utils/MetaDecorator";


export function Faq() {


    return (
        <div className="py-5">
            <MetaDecorator
                title="FAQ | The Place APS"
                description="Tutte le risposte alle domande frequenti."
                keywords="faq, domande frequenti, risposte, dubbi"
            />
            <Navbar />
            <Container className="pt-5">
                <div className="mb-4 mt-5 text-center">
                    <img
                        src={logoWhite}
                        height={100}
                        alt="logoThePlace"
                    />
                </div>
                <div className="text-center mb-5">
                    <h2 className="fw-bold">Domande frequenti</h2>
                    <p>Tutte le risposte alle tue domande.</p>
                </div>
            </Container>
            {
                /**

                 <Container className="text-center opacity-50 pt-4">
                 <FaWrench
                 size="7em"
                 className="mb-3"
                 />
                 <p className="fw-bold">Le FAQ saranno disponibili a breve</p>
                 </Container>

                 */
            }
            <Container className="text-dark faq">
                <Row className="d-flex justify-content-center">
                    <Col md={8}>
                        <Accordion>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Perché associarsi?</b></Accordion.Header>
                                <Accordion.Body>
                                    Associarsi significa contribuire alla missione di The Place nella creazione di iniziative socio - culturali. i meccanismi della stessa Associazione si basano sull’aspirazione dell’incremento costante nel tempo della rete e della sua community, questo per costruire un ambiente forte e denso di idee innovative.
                                    <br/><br/>
                                    Associandosi, si avrà diritto a partecipare a tutte le diverse iniziative, con la possibilità di esprimere le proprie idee a riguardo, oltre a poter proporre personalmente i propri progetti in linea con lo scopo sociale di The Place.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Come divento socio?</b></Accordion.Header>
                                <Accordion.Body>
                                    Puoi inviare la richiesta per diventare Socio Ordinario di THE PLACE APS compilando il form nella sezione <Link to="/register">REGISTRATI</Link>.
                                    <br/><br/>
                                    Ti verranno chiesti dati personali al fine di emettere la tua Tessera Associativa.
                                    <br/><br/>
                                    Quando la tua richiesta viene approvata, dovrai procedere al pagamento della quota associativa. I dettagli saranno comunicati per email.
                                    <br/><br/>
                                    Successivamente ti verranno inviate, per email, le credenziali temporanee per accedere alla tua area riservata.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Perché lasciare i miei dati personali?</b></Accordion.Header>
                                <Accordion.Body>
                                    I dati richiesti nella fase di registrazione sono i dati considerati requisiti minimi per entrare a far parte dell’Associazione. Tali dati non verranno in alcun modo condivisi con parti terze esterne a The Place.
                                    <br/><br/>
                                    I recapiti personali servono per avere modo di contattare il socio per aggiornarlo sull’operato e sulle iniziative dell’Associazione (tramite newsletter) e per avere a disposizione dati di natura anagrafica per provare l'effettiva esistenza del Socio.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Perché iscriversi alla newsletter?</b></Accordion.Header>
                                <Accordion.Body>
                                    La newsletter è un semplice strumento di collegamento tra il Socio e The Place stesso. rimane un modo semplice e diretto per comunicare gli aggiornamenti e le comunicazioni interne all’Associazione.
                                    <br/><br/>
                                    The Place si impegna a ridurre al minimo le comunicazioni, in modo da mantenere una certa discrezione utilizzando un metodo di comunicazione conciso e diretto.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Cosa e come si può proporre?</b></Accordion.Header>
                                <Accordion.Body>
                                    Le proposte possono essere di qualsiasi tipologia in linea con i principi di The Place.
                                    <br/>
                                    Idee relative all’organizzazione di eventi innovativi, collaborazioni con musei, mostre o performance artistiche.
                                    <br/><br/>
                                    La proposta può essere formulata all’interno dello spazio fornito nella sezione <a href="https://my.theplace.family/contribuisci">CONTRIBUISCI</a>; la stessa proposta deve preferibilmente essere inclusa in un testo riassuntivo, in modo tale che permetta di individuare le linee generali del progetto.
                                    <br/><br/>
                                    La sezione <a href="https://my.theplace.family/contribuisci">CONTRIBUISCI</a> può essere utilizzata anche come semplice metodo di contatto con The Place, anche se canali come la mail o i profili social sono più adatti a questo tipo di esigenza.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Non ricordo la password dell'area riservata</b></Accordion.Header>
                                <Accordion.Body>
                                    Per ripristinare la password del tuo account, scrivi una mail a <a href="mailto:developer@theplace.family">developer@theplace.family</a> indicando la mail che hai usato per registrarti. Il nostro team ti invierà per email un link valido per 24 ore per resettare la tua password.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={nanoid(10)}>
                                <Accordion.Header><b>Posso modificare i miei dati personali?</b></Accordion.Header>
                                <Accordion.Body>
                                    Sì, scrivi una mail a <a href="mailto:developer@theplace.family">developer@theplace.family</a> indicando i dati da modificare, nonché la mail che hai usato per creare l'account.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
