import {Navbar} from "./OnePageHome/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import logoTP from "./logo/logo_white.png";
import valentinoBg from "./imgs/valentino_bg.jpg"
import valentino01 from "./imgs/valentino_01.jpg"
import logoPolito from "./imgs/polito_white.png"
import {FaAngleDown} from "react-icons/fa";
import {Footer} from "./Footer";
import moment from "moment";
import {useState} from "react";
import firebase from "firebase/compat";


export function OpenCallValentino() {

    firebase.analytics().logEvent('opencall_valentino_view', {value:'true'})

    const endDate = moment('2022-03-31 23:59:59', 'YYYY-MM-DD H:mm:ss')
    const [countDown, setCountDown] = useState(0)
    setTimeout(() => {
        setCountDown(endDate.subtract(1, 'day').subtract(1, 'hour').add(1, 'minute').diff(moment(), 'x'),)
    }, 1000)

    return (
        <div className="pt-5">
            <Navbar />
            <Container fluid className="py-5 text-light bg-secondary d-flex justify-content-center align-items-center text-center"
                       style={{height:'95vh', backgroundImage:`url(${valentinoBg})`, backgroundSize:'cover', backgroundPosition:'center', backgroundAttachment:'fixed',
                           padding:'5em 0 5em 0'}}
            >
                <div>
                    <div className="mb-5 d-flex justify-content-evenly align-items-center">
                        <img
                            src={logoTP}
                            height={90}
                            alt="logoThePlace"
                        />
                        <a href="https://www.polito.it/" target="_blank" rel="noreferrer">
                            <img
                                src={logoPolito}
                                height={75}
                                alt="logoPoliTo"
                                className="iconHover opacity-100"
                            />
                        </a>
                    </div>
                    <div className="mb-5">
                        <h1 style={{fontSize:'8em', fontWeight:'800'}} className="d-none d-md-block">OPEN CALL</h1>
                        <h1 style={{fontSize:'4.5em', fontWeight:'800'}} className="d-block d-md-none">OPEN CALL</h1>
                    </div>
                    <div>
                        <p style={{letterSpacing:'5px', fontWeight:400}}>THE PLACE IS</p>
                        <h1 className="fw-bold">Castello del Valentino</h1>
                    </div>
                    <div className="position-absolute" style={{bottom: 25, left:'40%', right:'40%'}}>
                        <FaAngleDown
                            size="2.5em"
                        />
                    </div>
                </div>
            </Container>
            <Container className="py-5 my-5">
                <Row>
                    <Col xs={12} lg={7} className="d-flex justify-content-center align-items-center px-5">
                        <div>
                            <div className="mb-5">
                                <h1 className="fw-bold text-center text-md-start">Video and Music Performance</h1>
                            </div>
                            <div className="text-justify">
                                <p className="mb-3">
                                    Ciao 😄<br/>
                                    Stiamo organizzando un’iniziativa in collaborazione con il <a href="https://www.polito.it/" target="_blank" rel="noreferrer" className="text-decoration-underline">Politecnico di Torino</a> e cerchiamo ragazze e ragazzi da coinvolgere.
                                </p>
                                <p className="mb-3">
                                    Abbiamo l’opportunità di realizzare alcuni video artistico-musicali all’interno del <a href="https://castellodelvalentino.polito.it/" target="_blank" rel="noreferrer" className="text-decoration-underline">Castello del Valentino</a>, a Torino, e quindi siamo alla ricerca di giovani artisti da far esibire su questo palcoscenico.
                                </p>
                                <p className="mb-3">
                                    Nello specifico cerchiamo <u><b>cantanti, musicisti, ballerini, costumisti e videomaker</b></u>.
                                </p>
                                <p className="mb-5">
                                    Cerchiamo gruppi o solisti, cantanti e strumentisti di qualsiasi natura.<br/>
                                    Cerchiamo tecnici video e audio da integrare nel team di produzione video.<br/>
                                    Cerchiamo costumisti per vestire in maniera adeguata i performers.<br/>
                                    Cerchiamo anche qualcuno che balli sulle note di un pianoforte.
                                </p>
                            </div>
                            <div className="mb-3 text-center text-md-start">
                                <a href="https://forms.gle/VvhP2ZRdTbg3n82K8" target="_blank" rel="noreferrer">
                                    <Button
                                        variant="danger"
                                        className="rounded10 mb-3 mb-md-0"
                                        onClick={() => {
                                            firebase.analytics().logEvent('opencall_valentino_send_candidatura', {value:'true'})
                                        }}
                                    >
                                        Invia la tua candidatura
                                    </Button>
                                </a>
                                <a href="mailto:opencall@theplace.family" target="_blank" rel="noreferrer">
                                    <Button
                                        variant="outline-light"
                                        className="rounded10 mx-3"
                                        onClick={() => {
                                            firebase.analytics().logEvent('opencall_valentino_contattaci', {value:'true'})
                                        }}
                                    >
                                        Conattaci per altre info
                                    </Button>
                                </a>
                            </div>
                            <p className="mb-3 small fst-italic opacity-50 fw-lighter text-center text-md-start">
                                Le iscrizioni terminano il 31 marzo 2022 alle 23:59. <br className="d-block d-lg-none" />
                                La partecipazione si intende a titolo gratuito.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={5} className="d-flex justify-content-center align-items-center px-5">
                        <div className="mt-4 mt-lg-0">
                            <img
                                src={valentino01}
                                alt="Valentino"
                                className="imgHover"
                                style={{height:'100%', width:'100%', objectFit:'cover', boxShadow:'0px 0px 40px 0px #000'}}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{height:'30vh'}} className="bg-light text-dark d-flex justify-content-center align-items-center">
                <Container className="text-center">
                    <h5 className="text-uppercase mb-4">Le iscrizioni terminano tra:</h5>
                    <div className="d-flex justify-content-center align-items-start">
                        <div className="mx-2">
                            <h1 className="fw-bold">{moment(countDown).format('DD')}</h1>
                            <p>GIORNI</p>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">:</h1>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">{moment(countDown).format('H')}</h1>
                            <p>ORE</p>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">:</h1>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">{moment(countDown).format('mm')}</h1>
                            <p>MINUTI</p>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">:</h1>
                        </div>
                        <div className="mx-2">
                            <h1 className="fw-bold">{moment(countDown).format('ss')}</h1>
                            <p>SECONDI</p>
                        </div>
                    </div>
                    <h1 className="fw-bold">
                        {
                            //moment(countDown).format('DD : H : mm : ss')
                        }
                    </h1>
                </Container>
            </Container>
            <Footer />
        </div>
    )
}
