import {Helmet} from "react-helmet";


export function MetaDecorator({title, description, keywords}) {

    const basicTags = 'theplace, the place, theplacefamily, the place family, the place torino, theplacetorino, associazione the place, the place aps, torino, associazione culturale, associazione di promozione sociale, associazione culturale di promozione sociale, aps, theplaceaps, my the place, associati, statuto'

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords + ', ' + basicTags} />
        </Helmet>
    )
}
