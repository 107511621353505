import {BrowserRouter} from "react-router-dom";
import {Route, Switch, useLocation} from "react-router";
import {Page404} from "./utils/Page404";
import {Register} from "./Register";
import {useEffect} from "react";
import {OnePageHome} from "./OnePageHome/OnePageHome";
import {Faq} from "./Faq";
import {VerificaTessera} from "./utils/VerificaTessera";
import {Progetti} from "./Progetti";
import {EasterEgg} from "./EasterEgg";
import {Privacy} from "./Privacy";
import {OpenCallValentino} from "./OpenCallValentino";
import {FotoTovision} from "./FotoTovision";
import {MostraComala} from "./MostraComala/MostraComala";
import {Corpo, Donne, Extra, Grafiche, Protesta, Spleen, Underground, Urban, Viaggi} from "./MostraComala/Categorie";
import {Footer} from "./Footer";


export function Router() {


    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                <Switch>
                    {
                        /**
                         // WORK IN PROGRESS PAGE
                         <Route exact path="/">
                         <WorkInProgress />
                         </Route>

                         */
                    }

                    {
                        // STATIC PUBLIC
                    }
                    <Route exact path="/">
                        <OnePageHome />
                    </Route>
                    <Route exact path="/progetti">
                        <Progetti />
                    </Route>
                    <Route exact path="/faq">
                        <Faq />
                        <Footer />
                    </Route>
                    <Route exact path="/register">
                        <Register />
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy />
                    </Route>
                    <Route exact path="/open-call-castello-del-valentino">
                        <OpenCallValentino />
                    </Route>
                    <Route exact path="/foto-tovision">
                        <FotoTovision />
                        <Footer />
                    </Route>

                    {
                        // MOSTRA COMALA
                    }
                    <Route exact path="/iosonotheplace-vol-2">
                        <MostraComala />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/spleen">
                        <Spleen />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/corpo">
                        <Corpo />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/donne">
                        <Donne />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/viaggi">
                        <Viaggi />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/protesta">
                        <Protesta />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/underground">
                        <Underground />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/urban">
                        <Urban />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/grafiche">
                        <Grafiche />
                        <Footer />
                    </Route>
                    <Route path="/iosonotheplace-vol-2/extra">
                        <Extra />
                        <Footer />
                    </Route>


                    <Route exact path="/VR32NAlhI34zeqQKY9qtXWWgRZEfaRDFX9InFDn0">
                        <EasterEgg />
                    </Route>

                    {
                        // VERIFICA TESSERA
                    }
                    <Route path="/verificatessera/:id">
                        <VerificaTessera />
                    </Route>


                    {
                        // 404
                    }
                    <Route path="/404">
                        <Page404 />
                    </Route>
                    <Route>
                        <Page404 />
                    </Route>
                </Switch>
                </ScrollToTop>
            </BrowserRouter>
        </>
    )

    function ScrollToTop(props) {
        const location = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);

        return <>{props.children}</>
    };
}
