import {Navbar} from "./OnePageHome/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import logoTP from "./logo/logo_black.png";
import imgOropa from "./imgs/oropa.jpg"
import imgOropaBg from "./imgs/oropa-bg.png"
import imgMao from "./imgs/mao.jpg"
import imgMaoBg from "./imgs/mao-bg.jpg"
import logoMao from "./imgs/mao-white.png"
import logoFtm from "./imgs/ftm-white.png"
import imgCarignano from "./imgs/carignano.jpg"
import imgCarignanoBg from "./imgs/carignano-bg.jpg"
import logoCap from "./imgs/cap10100.png"
import imgCap from "./imgs/cap.jpg"
import imgCapBg from "./imgs/cap-bg.jpg"
import {Footer} from "./Footer";
import firebase from "firebase/compat";
import imgValentinoBg from "./imgs/valentino_bg.jpg"
import imgValentino01 from "./imgs/valentino_01.jpg"
import logoPolito from "./imgs/polito_white.png"
import imgToVision from "./imgs/tovision.png"
import imgToVisionBg from "./imgs/tovision-bg.jpg"
import logoToVision from "./imgs/logo-tovision.png"
import imgComala from "./imgs/comala.png"
import imgComalaBg from "./imgs/bg-comala.png"
import logoComala from "./imgs/comala-logo.png"
import {Link} from "react-router-dom";
import {MetaDecorator} from "./utils/MetaDecorator";


export function Progetti() {
    firebase.analytics().logEvent('projects_view', {value:'true'})


    return (
        <div className="pt-5">
            <MetaDecorator
                title="Progetti | The Place APS"
                description="Quello che facciamo. Progetti, iniziative, open call, cortometraggi, foto, mostre, esibizioni. Dai uno sguardo anche tu!"
                keywords="progetti, open call, iniziativa, iniziative, foto, mostre, mostra, video, esibizione, esibizioni, cortometraggio, cortometraggi, showreel"
            />
            <Navbar />
            <Container fluid className="py-5 text-dark" style={{backgroundColor:'#eeeeee'}}>
                <div className="mb-4 mt-5 text-center">
                    <img
                        src={logoTP}
                        height={100}
                        alt="logoThePlace"
                    />
                </div>
                <div className="text-center">
                    <h2 className="fw-bold">Progetti</h2>
                    <p>Le iniziative per promuovere la cultura tra i giovani.</p>
                </div>
            </Container>

            {
                // COMALA
            }
            <div className="text-dark"
                 style={{backgroundImage:`url(${imgComalaBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-2 order-md-1">
                            <div style={{height:'50vh'}}>
                                <img
                                    src={imgComala}
                                    alt="The Place is Comala"
                                    className="dropShadow imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover'}}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center text-end mb-5 mb-md-0 order-1 order-md-2 text-center text-md-end">
                            <div>
                                <div className="mb-4" style={{textShadow:'0px 0px 30px rgb(0,0,0,0.5)'}}>
                                    <p style={{letterSpacing:'5px', fontWeight:400}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">Comala</h1>
                                </div>
                                <p style={{textShadow:'0px 0px 30px rgb(0,0,0,0.5)'}}>
                                    Allestimento di una mostra temporanea all'interno degli spazi dell'Associazione Culturale Comala.
                                </p>
                                <div className="mt-5">
                                    <Link to="/iosonotheplace-vol-2">
                                        <Button
                                            variant="danger"
                                            className="rounded10"
                                            onClick={() => {

                                            }}
                                        >
                                            Scopri la mostra online
                                        </Button>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <a href="https://comala.it/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoComala}
                                            alt="logo Comala"
                                            height={70}
                                            className="logoSponsor"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // TOVISION
            }
            <div className="text-light"
                 style={{backgroundImage:`url(${imgToVisionBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5 mb-md-0 text-center text-md-start">
                            <div>
                                <div className="mb-4">
                                    <p style={{letterSpacing:'5px', fontWeight:300}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">ToVISION</h1>
                                </div>
                                <p>
                                    Esibizioni pubbliche nel centro di Torino, performate dai licei Torinesi.<br/>
                                    Venerdì <b>13 maggio 2022</b> dalle ore <b>16:00</b> in <b>Via Roma</b> e <b>Piazza Carignano</b>.
                                </p>
                                <div className="mt-5">
                                    <Link to="/foto-tovision">
                                        <Button
                                            variant="light"
                                            className="rounded10"
                                            onClick={() => {

                                            }}
                                        >
                                            Guarda le foto
                                        </Button>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <a href="https://tovision.it/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoToVision}
                                            alt="logo Mao"
                                            height={50}
                                            className="logoSponsor"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                            <div style={{height:'50vh'}}>
                                <img
                                    src={imgToVision}
                                    alt="Mao"
                                    className="imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover', dropShadow:'0px 0px 40px 0px #000'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // VALENTINO
            }
            <div className="text-light"
                style={{backgroundImage:`url(${imgValentinoBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-2 order-md-1">
                            <div style={{height:'50vh'}}>
                                <img
                                    src={imgValentino01}
                                    alt="Castello del Valentino"
                                    className="shadow imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover'}}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center text-end mb-5 mb-md-0 order-1 order-md-2 text-center text-md-end">
                            <div>
                                <div className="mb-4" style={{textShadow:'0px 0px 30px rgb(0,0,0,0.5)'}}>
                                    <p style={{letterSpacing:'5px', fontWeight:400}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">Castello del Valentino</h1>
                                </div>
                                <p style={{textShadow:'0px 0px 30px rgb(0,0,0,0.5)'}}>
                                    Performance artistico-musicali all'interno del Castello del Valentino.<br/>Invia la tua candidatura per esibirti all'interno del Castello!
                                </p>
                                <div className="mt-5">
                                    <a href="https://castellodelvalentino.polito.it/" target="_blank" rel="noreferrer">
                                        <Button
                                            variant="outline-light"
                                            className="rounded10 mx-3"
                                            onClick={() => {

                                            }}
                                        >
                                            Scopri il castello
                                        </Button>
                                    </a>
                                    <Button
                                        variant="danger"
                                        className="rounded10 disabled"
                                        onClick={() => {

                                        }}
                                    >
                                        Guarda i video
                                    </Button>
                                </div>
                                <div className="mt-5">
                                    <a href="https://www.polito.it/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoPolito}
                                            alt="logo PoliTo"
                                            height={70}
                                            className="logoSponsor"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // MAO
            }
            <div className="text-light"
                 style={{backgroundImage:`url(${imgMaoBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5 mb-md-0 text-center text-md-start">
                            <div>
                                <div className="mb-4">
                                    <p style={{letterSpacing:'5px', fontWeight:300}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">MAO</h1>
                                </div>
                                <p>
                                    Serie di performance musicali negli spazi suggestivi del MAO, <br className="d-none d-md-block"/> museo dedicato alla cultura orientale nel cuore di Torino.
                                </p>
                                <div className="mt-5">
                                    <a href="https://youtube.com/playlist?list=PLGhqhbNYAYYZGvNbZpGJtmJioWPgvutWq" target="_blank" rel="noreferrer">
                                        <Button
                                            variant="danger"
                                            className="rounded10"
                                            onClick={() => {
                                                firebase.analytics().logEvent('mao_playlist_click', {value:'true'})
                                            }}
                                        >
                                            Guarda i video
                                        </Button>
                                    </a>
                                    <a href="https://www.instagram.com/tv/CakYo2xoLF9/?utm_source=ig_web_copy_link" target="_blank" rel="noreferrer">
                                        <Button
                                            variant="outline-light"
                                            className="rounded10 mx-3"
                                            onClick={() => {
                                                firebase.analytics().logEvent('mao_video_click', {value:'true'})
                                            }}
                                        >
                                            Scopri il museo
                                        </Button>
                                    </a>
                                </div>
                                <div className="mt-5">
                                    <a href="https://www.maotorino.it/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoMao}
                                            alt="logo Mao"
                                            height={70}
                                            className="logoSponsor"
                                        />
                                    </a>
                                    <a href="https://www.fondazionetorinomusei.it/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoFtm}
                                            alt="logo Ftm"
                                            height={75}
                                            className="px-4 logoSponsor"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                            <div style={{height:'50vh'}}>
                                <img
                                    src={imgMao}
                                    alt="Mao"
                                    className="imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover', boxShadow:'0px 0px 40px 0px #000'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // CARIGNANO
            }
            <div className="text-light"
                 style={{backgroundImage:`url(${imgCarignanoBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-2 order-md-1">
                            <div style={{height:'40vh'}}>
                                <img
                                    src={imgCarignano}
                                    alt="Piazza Carignano"
                                    className="shadow imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover'}}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center text-end text-dark mb-5 mb-md-0 order-1 order-md-2 text-center text-md-end">
                            <div>
                                <div className="mb-4">
                                    <p style={{letterSpacing:'5px', fontWeight:400}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">Piazza Carignano</h1>
                                </div>
                                <p>
                                    Performance pubblica, in Piazza Carignano, di numerosi musicisti e cantanti che hanno catturato l'attenzione di molti passanti.
                                </p>
                                <a href="https://www.instagram.com/tv/Cb7e-_dqX7H/" target="_blank" rel="noreferrer">
                                    <Button
                                        variant="danger"
                                        className="rounded10 mt-5"
                                    >
                                        Guarda il video
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // CAP10100
            }
            <div className="text-light"
                 style={{backgroundImage:`url(${imgCapBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5 mb-md-0 text-center text-md-start">
                            <div>
                                <div className="mb-4">
                                    <p style={{letterSpacing:'5px', fontWeight:300}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">CAP10100</h1>
                                </div>
                                <p>
                                    Allestimento di una mostra fotografica temporanea. <br/>
                                    L'inaugurazione è stata accompagnata da una serata tech-house.
                                </p>
                                <div className="mt-5">
                                    <a href="https://www.instagram.com/tv/CXOsKITqNQB/?utm_source=ig_web_copy_link" target="_blank" rel="noreferrer">
                                        <Button
                                            variant="danger"
                                            className="rounded10"
                                            onClick={() => {
                                                firebase.analytics().logEvent('cap_video_click', {value:'true'})
                                            }}
                                        >
                                            Guarda il video
                                        </Button>
                                    </a>
                                    <a href="https://drive.google.com/drive/folders/1dHcghVt7JevHjuT8R29FvM64PL_9y7Mo?usp=sharing" target="_blank" rel="noreferrer">
                                        <Button
                                            variant="outline-light"
                                            className="rounded10 mx-3"
                                            onClick={() => {
                                                firebase.analytics().logEvent('cap_foto_click', {value:'true'})
                                            }}
                                        >
                                            Guarda le foto
                                        </Button>
                                    </a>
                                </div>
                                <div className="mt-5 opacity-75">
                                    <a href="https://www.cap10100.com/" target="_blank" rel="noreferrer">
                                        <img
                                            src={logoCap}
                                            alt="logo CAP10100"
                                            height={40}
                                            className="logoSponsor"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                            <div style={{height:'40vh'}}>
                                <img
                                    src={imgCap}
                                    alt="Santuario di Oropa"
                                    className="shadow imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                // OROPA
            }
            <div className="bg-light text-dark"
                style={{backgroundImage:`url(${imgOropaBg})`, backgroundSize:'cover', backgroundPosition:'center', padding:'5em 0 5em 0'}}
            >
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center order-2 order-md-1">
                            <div style={{height:'50vh'}}>
                                <img
                                    src={imgOropa}
                                    alt="Santuario di Oropa"
                                    className="shadow imgHover"
                                    style={{height:'100%', width:'100%', objectFit:'cover'}}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center text-end mb-5 mb-md-0 order-1 order-md-2 text-center text-md-end">
                            <div>
                                <div className="mb-4">
                                    <p style={{letterSpacing:'5px', fontWeight:300}}>THE PLACE IS</p>
                                    <h1 className="fw-bold">Santuario di Oropa</h1>
                                </div>
                                <p>
                                    Live dj-set at <b><i>Santuario di Oropa</i></b>, alla console <b><i>@Sem</i></b><br/>
                                    Alpi Biellesi || 1200m di altezza || IV secolo d.C. || Patrimonio dell'UNESCO <br/>
                                    &#200; possibile creare un legame tra musica, natura ed individuo?
                                </p>
                                <a href="https://www.youtube.com/watch?v=ogQPvgh-6eo" target="_blank" rel="noreferrer">
                                    <Button
                                        variant="danger"
                                        className="rounded10 mt-5"
                                        onClick={() => {
                                            firebase.analytics().logEvent('oropa_video_click', {value:'true'})
                                        }}
                                    >
                                        Guarda il video
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    )
}
