import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import firebase from "firebase/compat";
import moment from "moment";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import logoWhite from "../logo/logo_white.png";
import {Link} from "react-router-dom";


export function VerificaTessera() {

    const history = useLocation().pathname.split('/')[2]
    const [data, setData] = useState({})
    useEffect(() => {
        firebase.firestore().collection('users').doc(history).onSnapshot(snapshot => {
            snapshot.exists ?
                setData(snapshot)
                :
                setData({exists: false})
        })
    }, [])

    return (
        <div>
            <Container className="d-flex justify-content-center align-items-center text-center pb-5"
                       style={{height: '100vh'}}>
                <div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Row className="mb-5">
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Link
                                    to="/"
                                >
                                    <img
                                        src={logoWhite}
                                        height={60}
                                        alt="logoThePlace"
                                    />
                                </Link>
                            </Col>
                            <Col className="text-xs-center text-md-start">
                                <h3 className="fw-bold">The Place</h3>
                                <p className="small text-uppercase opacity-75 fw-light">Associazione culturale</p>
                            </Col>
                        </Row>
                    </div>
                    <h3 className="fw-bold mb-5 d-none">Verifica Tessera</h3>
                    <div>
                        {
                            data ?
                                data.exists===true ?
                                    Number(data.data().anno)>=Number(moment().format('YYYY')) ?
                                        <div className="cardVerificaTessera py-4 px-5">
                                            <FaCheckCircle
                                                size="4em"
                                                className="mb-2 text-success"
                                            />
                                            <h5 className="fw-bold text-success text-uppercase">La tessera è valida</h5>
                                            <div className="mt-3">
                                                <p className="small">Intestata a:</p>
                                                <p><span className="fw-bold">{data.data().cognome}</span>, {data.data().nome}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="cardVerificaTessera py-4 px-5">
                                            <FaTimesCircle
                                                size="4em"
                                                className="mb-2 text-danger"
                                            />
                                            <h5 className="fw-bold text-danger text-uppercase">La tessera è scaduta</h5>
                                        </div>
                                    :
                                    <div className="cardVerificaTessera py-4 px-5">
                                        <FaTimesCircle
                                            size="4em"
                                            className="mb-2 text-danger"
                                        />
                                        <h5 className="fw-bold text-danger text-uppercase">La tessera non esiste</h5>
                                    </div>
                                :
                                <div>
                                    <Spinner animation="border" variant="light" className="mb-1"/>
                                    <p>Verifica in corso...</p>
                                </div>
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}
